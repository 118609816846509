import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgBike: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="bike_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </mask>
      <g mask="url(#bike_svg__a)">
        <path
          d="M5 22c-1.383 0-2.563-.488-3.538-1.462C.488 19.562 0 18.383 0 17s.487-2.563 1.462-3.537C2.438 12.488 3.617 12 5 12s2.563.488 3.537 1.463C9.512 14.438 10 15.617 10 17s-.488 2.563-1.463 3.538C7.563 21.512 6.383 22 5 22zm0-2c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 008 17c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 005 14c-.833 0-1.542.292-2.125.875A2.893 2.893 0 002 17c0 .833.292 1.542.875 2.125A2.893 2.893 0 005 20zm6-1v-5l-3.2-2.8a1.78 1.78 0 01-.45-.637c-.1-.242-.15-.496-.15-.763s.054-.52.162-.763c.109-.241.255-.454.438-.637l2.8-2.8c.2-.2.43-.35.688-.45.258-.1.529-.15.812-.15.283 0 .554.05.813.15.258.1.487.25.687.45l1.9 1.9c.467.467 1 .833 1.6 1.1.6.267 1.233.4 1.9.4v2c-.95 0-1.854-.183-2.712-.55A7.22 7.22 0 0114 8.9l-.8-.8-2.4 2.4 2.2 2.3V19h-2zm4.5-13.5c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0113.5 3.5c0-.55.196-1.02.588-1.413A1.926 1.926 0 0115.5 1.5c.55 0 1.02.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.926 1.926 0 0115.5 5.5zM19 22c-1.383 0-2.563-.488-3.537-1.462C14.488 19.562 14 18.383 14 17s.488-2.563 1.463-3.537C16.438 12.488 17.617 12 19 12s2.563.488 3.538 1.463C23.512 14.438 24 15.617 24 17s-.488 2.563-1.462 3.538C21.562 21.512 20.383 22 19 22zm0-2c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0022 17c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0019 14c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0016 17c0 .833.292 1.542.875 2.125A2.893 2.893 0 0019 20z"
          fill="currentColor"
        />
      </g>
    </Icon>
  )
}
export default SvgBike
