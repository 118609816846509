import { formatWholeNumber } from '@kijiji/number/formatWholeNumber'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { ListingDetailsDivider } from '@/components/shared/core-listing-card/card-details/ListingDetailsDivider'
import { AutosAttributeListItem } from '@/components/srp/listing-card/autos-attribute-list/AutosAttributeListItem'
import { AutosAttributeListFlex } from '@/components/srp/listing-card/autos-attribute-list/styled'
import { type AutosListingAttributes } from '@/components/srp/listing-card/types'
import { CAR_FUEL_VALUES } from '@/features/attributes/constants/attributes'
import { ROUTE_LOCALE, useLocale } from '@/hooks/useLocale'
import { capitalizeString } from '@/utils/string'

type AutosAttributeListProps = {
  attributes: AutosListingAttributes
}

// TODO: We can migrate this to use the List component from @kijiji/ui once it's able to support all the small tweaks we need.
export const AutosAttributeList = ({ attributes }: AutosAttributeListProps) => {
  const { t } = useTranslation('srp')
  const { km, transmission, fuelType } = attributes
  const theme = useTheme()
  const { routeLocale } = useLocale()

  const formattedKilometers = km
    ? formatWholeNumber(Number(km), routeLocale === ROUTE_LOCALE.en)
    : undefined

  const isFuelTypeVisible = fuelType && fuelType !== CAR_FUEL_VALUES.OTHER

  return (
    <AutosAttributeListFlex
      flexDirection="row"
      flexWrap="wrap"
      columnGap={theme.spacing.defaultSmall}
      alignItems="center"
    >
      {km ? (
        <AutosAttributeListItem
          data-testid="autos-attribute-kilometers"
          text={t('listing.attributes.kilometers.value', { kms: formattedKilometers })}
        />
      ) : null}

      {km && transmission ? <ListingDetailsDivider variant="dot" visible={true} /> : null}

      {transmission ? (
        <AutosAttributeListItem data-testid="autos-attribute-transmission" text={transmission} />
      ) : null}

      {transmission && isFuelTypeVisible ? (
        <ListingDetailsDivider variant="dot" visible={true} />
      ) : null}

      {isFuelTypeVisible ? (
        <AutosAttributeListItem
          data-testid="autos-attribute-fuel-type"
          text={capitalizeString(fuelType)}
        />
      ) : null}
    </AutosAttributeListFlex>
  )
}
