import { type ListingLocationInterface } from '@kijiji/generated/graphql-types'

/**
 * Takes the listing location and returns the proximity value in meters
 */
export const getListingProximityLabel = (
  listingLocation?: ListingLocationInterface
): string | undefined => {
  const distance =
    listingLocation && 'distance' in listingLocation ? listingLocation.distance : undefined

  if (!distance) return

  /** Transform value to Km */
  const distanceKm = distance / 1000

  /** Round up the distance value if necessary / return proper label */
  return distanceKm % 1 === 0 ? `${distanceKm}km` : `< ${Math.ceil(distanceKm)}km`
}
