import { CATEGORIES } from '@kijiji/category'
import { type PersonalAccount } from '@kijiji/generated/graphql-types'
import { type NextRouter } from 'next/router'
import { type Session } from 'next-auth'

import { ICO_UTM_PARAMS } from '@/constants/ico'
import { PROVINCES_ABBREVIATION } from '@/constants/location'
import { navigateToPAF } from '@/domain/autocanada/navigateToPAF'
import { ATTRIBUTES } from '@/features/attributes/constants/attributes'
import { type RouteLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { getAutoCanadaWebLabel } from '@/lib/ga/events/autoCanada'
import { type VipQueryAttributeAll } from '@/types/listings'

const { CAR_MILEAGE, VIN } = ATTRIBUTES
const VALUE_ESTIMATE_ATTRIBUTE_MAP: { [x: string]: string } = {
  [CAR_MILEAGE]: 'odometer',
  [VIN]: 'vin',
}

type AttributeParameters = { odometer?: string; vin?: string }
type UserParameters = {
  email?: string
  firstName?: string
  lastName?: string
  phone?: string
  userId?: string
}
type DataParameters = AttributeParameters & UserParameters & { ctaId: string; province?: string }
type UTMParameters = {
  utmCampaign: string
  utmContent: string
  utmMedium: string
  utmSource: string
}

type ValueEstimateModalParameters = {
  data: DataParameters
  locale: string
  utmParams: UTMParameters
  onSubmittedForEstimate: () => void
  onIcoPostToKijiji: () => void
  onClose?: () => void
  onError?: () => void
}

type OpenAutocanValueEstimateModalProps = {
  ctaId: string
  customGAParameters?: Record<string, unknown>
  isMobile: boolean
  onClose?: () => void
  onError?: () => void
  /**
   * Either receive the province abbreviation or the province ID
   * Depending on the data the page has access to
   */
  provinceId?: number
  provinceAbbreviation?: string
  push: NextRouter['push']
  routeLocale: RouteLocale
  /**
   * Either receive user or profile data depending on the data the page has access to
   */
  user?: Session['user']
  profileData?: PersonalAccount
  utmCampaign?: string
  utmContent: string
  /**
   * Either pass the list of attributes
   * Or each individual value depending on the data the page has access to
   */
  attributes?: VipQueryAttributeAll
} & AttributeParameters

export const openAutocanValueEstimateModal = ({
  attributes,
  ctaId,
  customGAParameters,
  isMobile,
  odometer,
  onClose,
  onError,
  profileData,
  provinceAbbreviation,
  provinceId,
  push,
  routeLocale,
  user,
  utmCampaign = ICO_UTM_PARAMS.UTM_CAMPAIGN_ICO,
  utmContent,
  vin,
}: OpenAutocanValueEstimateModalProps): void => {
  let province
  if (provinceAbbreviation) {
    province = provinceAbbreviation
  } else if (provinceId) {
    province = PROVINCES_ABBREVIATION[provinceId]
  }

  const attributeParameters = attributes?.reduce((acc: AttributeParameters, curr) => {
    const canonicalName = curr?.canonicalName ?? ''
    const value = curr?.values[0]
    const valueEstimateAttribute = VALUE_ESTIMATE_ATTRIBUTE_MAP[canonicalName]

    if (!!valueEstimateAttribute && !!value) {
      return { ...acc, [valueEstimateAttribute]: value }
    }

    return acc
  }, {})

  const userName = profileData?.name ?? user?.name ?? ''
  const userEmail = profileData?.email ?? user?.email ?? ''
  const userId = profileData?.id ?? user?.sub ?? ''
  const phone = profileData?.phoneNumber ?? ''

  const data: ValueEstimateModalParameters['data'] = {
    ctaId,
    province,
    odometer: odometer ?? attributeParameters?.odometer,
    vin: vin ?? attributeParameters?.vin,
    email: userEmail,
    firstName: userName?.split(' ')[0] ?? '',
    lastName: userName?.split(' ').pop() ?? '',
    phone,
    userId,
  }
  const utmParams: ValueEstimateModalParameters['utmParams'] = {
    utmContent,
    utmCampaign,
    utmSource: ICO_UTM_PARAMS.UTM_SOURCE,
    utmMedium: isMobile ? ICO_UTM_PARAMS.UTM_MEDIUM_MWEB : ICO_UTM_PARAMS.UTM_MEDIUM_WEB,
  }

  const modalParameters: ValueEstimateModalParameters = {
    data,
    locale: routeLocale,
    utmParams,
    onSubmittedForEstimate: () => {
      trackEvent({
        action: GA_EVENT.ValueEstimateComplete,
        label: getAutoCanadaWebLabel(),
        customParameters: customGAParameters,
      })
    },
    onIcoPostToKijiji: () => navigateToPAF(push, CATEGORIES.CARS_AND_TRUCKS_CATEGORY_ID),
    onClose,
    onError,
  }

  // Initialize the value estimate modal from AutoCanada's SDK.
  global.window.acIco?.initModal('value-estimate', 'kijiji', modalParameters)
}
