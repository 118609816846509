import { isAnyServicesCategory } from '@kijiji/category'
import { useTranslation } from 'next-i18next'
import { type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import { ShowAtOrLarger, ShowWhenSmaller } from '@/components/shared/breakpoint'
import { ClientRender } from '@/components/shared/client-render'
import { ImageYAMS } from '@/components/shared/image/ImageYAMS'
import { FavouriteButton } from '@/components/shared/listing/FavouriteButton'
import { AutosAttributeList } from '@/components/srp/listing-card/autos-attribute-list'
import { AutosBadges } from '@/components/srp/listing-card/autos-badges'
import { GetMediaFavouriteButtonSize } from '@/components/srp/listing-card/get-media-favourite-button-size'
import { ListingDetails } from '@/components/srp/listing-card/listing-details'
import { ListingImageBadge } from '@/components/srp/listing-card/listing-image-badge'
import { ListingPrice } from '@/components/srp/listing-card/listing-price'
import { ListingPriceVariant } from '@/components/srp/listing-card/listing-price/ListingPrice'
import {
  AutosCardDescriptionContainer,
  AutosListingCardBottomData,
  AutosListingCardContainer,
  AutosListingCardDivider,
  AutosListingCardHeading,
  AutosListingCardLogoContainer,
  AutosListingCardTopData,
  AutosListingImageArea,
  AutosListingImageBase,
  ExpandDescriptionButton,
  ListingCardBody,
  ListingCardDetails,
  ListingCardLink,
  ListingRightSide,
  ThinContentListingCardLink,
} from '@/components/srp/listing-card/styled'
import {
  type AutosListingAttributes,
  type AutosListingCardProps,
} from '@/components/srp/listing-card/types'
import {
  getCarsVehiclesAttributes,
  getTransmissionTypeLiteralKey,
  SRP_ATTRIBUTES,
} from '@/components/srp/search-list/domain'
import { highlightKeywords } from '@/components/srp/search-list/highlightKeywords'
import { Showcase } from '@/components/srp/showcase'
import { type ShowcaseProps } from '@/components/srp/showcase/Showcase'
import { autosListingMainImageUrlRuleSize, autosShowcaseThumbnailSizes } from '@/constants/listings'
import { getListingBadgeLabel } from '@/domain/listings/getListingBadgeLabel'
import { getListingProximityLabel } from '@/domain/srp/getListingProximityLabel'
import { truncateText } from '@/domain/srp/truncateText'
import { ATTRIBUTES } from '@/features/attributes/constants/attributes'
import { getAttributesCanonicalValuesDictionary } from '@/features/attributes/utils/getAttributesCanonicalValuesDictionary'
import { PriceInsightsBadge } from '@/features/listing/components/price-insights/PriceInsightsBadge'
import { getPriceClassification } from '@/features/listing/utils/getPriceClassification'
import { useGetSearchKeyword } from '@/hooks/keywords/useGetSearchKeywords'
import { useLocale } from '@/hooks/useLocale'
import { RemoteToggles } from '@/lib/firebase/config/toggles'
import { RemoteParamKeys, useToggle } from '@/lib/firebase/hooks'
import { BadgeSize } from '@/ui/atoms/badge/Badge'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { RotatingChevron } from '@/ui/atoms/rotating-chevron'
import { Spacing } from '@/ui/atoms/spacing'

/**
 * `AutosListingCard` is a component that renders a listing card for autos categories
 *
 * @component
 */
export const AutosListingCard: FC<AutosListingCardProps> = ({
  item,
  isMobile,
  index,
  price,
  isInTopAdsSection,
  handleTrackListingClick,
}) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation('srp')
  const { routeLocale } = useLocale()
  const { keyword: globalKeyword } = useGetSearchKeyword()
  const priceInsightToggle = useToggle(RemoteToggles.AUTOS_PRICE_INSIGHT.key)

  const rating = getPriceClassification(item.price)?.rating ?? null

  const [showDescription, setShowDescription] = useState<boolean>(false)

  const { id, categoryId, attributes, flags, url, title, imageUrls, adSource, sortingDate } = item

  const attributesDictionary = getAttributesCanonicalValuesDictionary(attributes?.all)
  const { carfax, cpo, dealerUpdates, isNew } = getCarsVehiclesAttributes(
    attributesDictionary,
    categoryId,
    routeLocale,
    t
  )
  const msrp = (isNew && price.msrp) || undefined

  const listingAttributes: AutosListingAttributes = {
    dealerUpdates,
    hasCarfaxReport: carfax,
    isCertifiedPreOwned: cpo,
    msrp,
    km: attributesDictionary[ATTRIBUTES.CAR_MILEAGE]?.[0],
    transmission: attributesDictionary[ATTRIBUTES.CAR_TRANSMISSION]?.[0]
      ? t(getTransmissionTypeLiteralKey(+attributesDictionary[ATTRIBUTES.CAR_TRANSMISSION]?.[0]))
      : undefined,
  }

  const { hasCarfaxReport, isCertifiedPreOwned } = listingAttributes

  const shouldShowAttributes = Object.keys(listingAttributes)?.length > 0
  const hasContactlessServices = !!dealerUpdates.length
  const description = item.description || ''

  const logoSrc = attributesDictionary[SRP_ATTRIBUTES.LOGO]?.[0]

  const shouldRenderBottomData =
    logoSrc || description || hasCarfaxReport || isCertifiedPreOwned || hasContactlessServices

  const shouldRenderBadges = hasCarfaxReport || isCertifiedPreOwned || hasContactlessServices

  const { showcase, highlight, categorySpecificBadge } = flags

  const showcaseImages = showcase ? item.imageUrls?.slice(1) : []

  const renderShowcase = (showcaseProps?: Partial<ShowcaseProps>) => {
    if (!showcaseImages?.length) return null

    return (
      <Showcase
        thumbnailSizes={autosShowcaseThumbnailSizes}
        itemBorderRadius="small"
        itemListGap="defaultSmall"
        maxNumberOfPictures={9}
        seoUrl={url}
        showcaseImages={showcaseImages}
        handleListingCardClick={handleTrackListingClick}
        showDivider={false}
        baseAltText={truncateText(description, 100)}
        {...showcaseProps}
      />
    )
  }

  const getListingImageSize = () => {
    return highlight ? 'xxLarge' : 'xLarge'
  }

  const expandDescription = () => {
    setShowDescription(!showDescription)
  }

  /** If is a conventional listing, then find all the values needed to render it */
  const imageBadge = getListingBadgeLabel({
    categoryId: categoryId,
    categorySpecificBadge: !!categorySpecificBadge,
  })

  const mainImage = imageUrls?.[0]

  /**
   * Distance
   * Should return the proximity value from the user in meters
   */
  const proximity = getListingProximityLabel(item.location)

  const thinContentLinkToggle = useToggle(RemoteParamKeys.SRP_THIN_CONTENT_LINKS)
  const mainImageAlt = truncateText(description, 100) ?? title

  return (
    <AutosListingCardContainer data-testid="listing-card" data-listingid={id}>
      <AutosListingCardTopData isHighlight={!!highlight}>
        <AutosListingImageArea>
          <AutosListingImageBase
            badge={imageBadge ? <ListingImageBadge imageBadge={imageBadge} /> : null}
            data-testid={`listing-card-image-container${highlight ? '-highlight' : ''}`}
            imageComponent={
              <ImageYAMS
                alt={mainImageAlt}
                data-testid="listing-card-image"
                extension="WEBP"
                height={300}
                loading={index < 2 ? 'eager' : 'lazy'}
                priority={isInTopAdsSection && index === 0}
                skipNextImage
                src={mainImage}
                width={autosListingMainImageUrlRuleSize}
              />
            }
            size={getListingImageSize()}
          />

          {!!showcaseImages?.length && (
            <ShowWhenSmaller breakpoint="medium">
              {renderShowcase({ itemListGap: 'mini', minNumberOfPictures: 3 })}
            </ShowWhenSmaller>
          )}
        </AutosListingImageArea>

        <ListingCardDetails>
          <ListingCardBody>
            <Flex alignItems="center" gap={spacing.mini}>
              <ListingPrice
                displayInlinePrice={!!highlight}
                msrp={msrp}
                priceAmount={price.amount}
                priceOriginal={price.original}
                type={price.type}
                surcharges={price.surcharges}
                variant={ListingPriceVariant.Autos}
              />
              {rating && priceInsightToggle?.enabled ? (
                <PriceInsightsBadge insightType={rating} />
              ) : null}
            </Flex>
            <AutosListingCardHeading
              data-testid="listing-title"
              forwardedAs="h3"
              color={colors.grey.primary}
              size="small"
              weight={isMobile ? 'medium' : 'regular'}
            >
              {thinContentLinkToggle?.enabled && isAnyServicesCategory(categoryId) ? (
                /* KJCA-5020: Only enabled for services category for now */
                <ThinContentListingCardLink
                  data-testid="thin-listing-link"
                  href={url}
                  onClick={handleTrackListingClick}
                  variant="grey"
                  content={description ?? undefined}
                >
                  {highlightKeywords(id, title, globalKeyword)}
                </ThinContentListingCardLink>
              ) : (
                <ListingCardLink
                  data-testid="listing-link"
                  href={url}
                  onClick={handleTrackListingClick}
                  variant="grey"
                >
                  {highlightKeywords(id, title, globalKeyword)}
                </ListingCardLink>
              )}
            </AutosListingCardHeading>

            {shouldShowAttributes ? <AutosAttributeList attributes={listingAttributes} /> : null}

            <Spacing mBottom={spacing.defaultSmall}>
              <ListingDetails
                sortingDate={isInTopAdsSection ? undefined : sortingDate}
                locationName={item.location.name}
                proximity={proximity}
                listingAdSource={adSource}
                isMobileFormat={isMobile}
              />
            </Spacing>
            <ShowAtOrLarger breakpoint="medium">{renderShowcase()}</ShowAtOrLarger>
          </ListingCardBody>

          <ListingRightSide>
            <ClientRender>
              <GetMediaFavouriteButtonSize>
                {({ size }) => <FavouriteButton colorScheme="purple" listingId={id} size={size} />}
              </GetMediaFavouriteButtonSize>
            </ClientRender>
          </ListingRightSide>
        </ListingCardDetails>
      </AutosListingCardTopData>

      {shouldRenderBottomData && (
        <>
          <AutosListingCardDivider
            top={spacing.defaultSmall}
            bottom={spacing.defaultSmall}
            showDivider={!!(logoSrc || shouldRenderBadges)}
          />

          <AutosListingCardBottomData
            flexWrap="wrap"
            gap={logoSrc ? spacing.default : '0'}
            alignItems="center"
          >
            {logoSrc && (
              <AutosListingCardLogoContainer>
                <ImageYAMS data-testid="logo-container" fill src={logoSrc} alt="" />
              </AutosListingCardLogoContainer>
            )}
            {shouldRenderBadges && (
              <AutosBadges horizontalOverflow hasDescription={!!description}>
                {isCertifiedPreOwned && (
                  <AutosBadges.CertifiedPreOwned size={BadgeSize.LARGE} variant="badge" />
                )}
                {hasCarfaxReport && <AutosBadges.CarFax size={BadgeSize.LARGE} variant="badge" />}
                {hasContactlessServices && (
                  <AutosBadges.Contactless size={BadgeSize.LARGE} variant="badge" />
                )}
              </AutosBadges>
            )}
            {description && (
              <ExpandDescriptionButton
                onClick={expandDescription}
                data-testid="listing-description-button"
                aria-label={t('listing.description_button.label')}
                aria-expanded={showDescription}
                aria-controls={`expandable-description-${id}`}
              >
                <RotatingChevron isOpen={showDescription} />
              </ExpandDescriptionButton>
            )}
          </AutosListingCardBottomData>
          <AutosCardDescriptionContainer
            showDescription={showDescription}
            id={`expandable-description-${id}`}
          >
            <BodyText color={colors.grey.primary} data-testid="listing-description" size="small">
              {highlightKeywords(id, description, globalKeyword)}
            </BodyText>
          </AutosCardDescriptionContainer>
        </>
      )}
    </AutosListingCardContainer>
  )
}
