import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgWalk: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="walk_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </mask>
      <g mask="url(#walk_svg__a)">
        <path
          d="M7 23L9.8 8.9 8 9.6V13H6V8.3l5.05-2.15c.233-.1.48-.158.737-.175.259-.017.505.017.738.1.233.083.454.2.662.35.209.15.38.342.513.575l1 1.6c.433.7 1.02 1.275 1.762 1.725.742.45 1.588.675 2.538.675v2c-1.167 0-2.208-.242-3.125-.725a8.03 8.03 0 01-2.35-1.85L12.9 13.5l2.1 2V23h-2v-6.5l-2.1-1.6L9.1 23H7zm6.5-17.5c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0111.5 3.5c0-.55.196-1.02.588-1.413A1.926 1.926 0 0113.5 1.5c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413s-.196 1.02-.588 1.412A1.926 1.926 0 0113.5 5.5z"
          fill="currentColor"
        />
      </g>
    </Icon>
  )
}
export default SvgWalk
