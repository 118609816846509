import { BorderRadiusKeys, ThemeProps } from '@kijiji/theme'
import styled, { DefaultTheme } from 'styled-components'

import { applyResponsiveStyle } from '@/ui/helpers/applyResponsiveStyle'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'
import { ResponsiveProp } from '@/ui/typings/helpers'

import { BadgeSize, BadgeVariant } from './Badge'

const getPaddingBySize = (size: BadgeSize, theme: DefaultTheme) => {
  switch (size) {
    case BadgeSize.SMALL:
      return `0.3rem ${theme.spacing.mini}`
    case BadgeSize.MEDIUM:
      return '0.1rem 0.3rem'
    case BadgeSize.LARGE:
      return '0.3rem 0.8rem'
    case BadgeSize.XLARGE:
      return '0.4rem 0.8rem'
    case BadgeSize.XXLARGE:
      return '0.7rem 1.2rem'
    default:
      return '0.1rem 0.3rem'
  }
}

const getBorderByVariant = (
  variant: BadgeVariant,
  theme: DefaultTheme,
  borderColor?: string
) => {
  if (borderColor) {
    return `0.1rem solid ${borderColor};`
  }

  switch (variant) {
    case BadgeVariant.LIGHT1:
      return `0.1rem solid ${theme.colors.grey.light1};`
    case BadgeVariant.LIGHT3:
      return `0.1rem solid ${theme.colors.grey.light3}`
    default:
      return `0.1rem solid ${theme.colors.grey.light1};`
  }
}

const getTypographyBySize = (size: BadgeSize, theme: DefaultTheme) => {
  switch (size) {
    case BadgeSize.SMALL:
      return styleFromTypography(theme.typography.body.xxSmall)
    case BadgeSize.MEDIUM:
      return styleFromTypography(theme.typography.body.xSmall)
    case BadgeSize.LARGE:
      return styleFromTypography(theme.typography.body.xSmall)
    case BadgeSize.XLARGE:
      return styleFromTypography(theme.typography.body.small)
    case BadgeSize.XXLARGE:
      return styleFromTypography(theme.typography.body.small)
    default:
      return styleFromTypography(theme.typography.body.xSmall)
  }
}

const getResponsivePadding = (size: BadgeSize, theme: ThemeProps) =>
  `padding: ${getPaddingBySize(size, theme)};`

const getResponsiveTypography = (size: BadgeSize, theme: ThemeProps) =>
  getTypographyBySize(size, theme)

type BadgeElementProps = {
  size: ResponsiveProp<BadgeSize>
  variant: BadgeVariant
  borderRadius: BorderRadiusKeys
  color?: string
  backgroundColor?: string
  weight: keyof ThemeProps['fontWeight']
  gap?: string
  isBorderless: boolean
  borderColor?: string
}

export const BadgeElement = styled.span<BadgeElementProps>(
  ({
    theme,
    size,
    variant,
    borderRadius,
    color,
    backgroundColor,
    weight,
    gap,
    isBorderless,
    borderColor,
  }) => `
  font-weight: ${theme.fontWeight[weight]};
  color: ${color ?? theme.colors.grey.light1};
  border-radius: ${theme.borderRadius[borderRadius]};
  background-color: ${backgroundColor ?? theme.colors.white};
  ${applyResponsiveStyle(size, theme, getResponsivePadding)};
  border: ${isBorderless ? 'none' : getBorderByVariant(variant, theme, borderColor)};
  ${applyResponsiveStyle(size, theme, getResponsiveTypography)};
  display: flex;
  align-items: center;
  gap: ${gap ?? theme.spacing.mini};
  height: fit-content;
  width: fit-content;
  white-space: nowrap;
`
)
