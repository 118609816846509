import { type ListingPriceRating } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { PRICE_INSIGHT_COLOUR_MAP } from '@/features/listing/constants/priceInsight'
import { Badge, BadgeSize } from '@/ui/atoms/badge'
import { type ResponsiveProp } from '@/ui/typings/helpers'

interface PriceInsightsBadgeProps {
  insightType: ListingPriceRating
  size?: ResponsiveProp<BadgeSize>
}

export const PriceInsightsBadge: React.FC<PriceInsightsBadgeProps> = ({ insightType, size }) => {
  const { t } = useTranslation([TRANSLATION_KEYS.LISTING])
  const { colors } = useTheme()

  const colorMap = PRICE_INSIGHT_COLOUR_MAP(colors)

  const config = colorMap[insightType]

  const label = t(`listing:price_insights.badge.label.${insightType.toLowerCase()}`)

  return (
    <Badge
      label={label}
      backgroundColor={config.background}
      borderColor={config.border}
      color={colors.grey.primary}
      size={size || { medium: BadgeSize.XLARGE, small: BadgeSize.SMALL }}
      borderRadius="rounded"
      weight="medium"
    />
  )
}
