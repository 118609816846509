import styled from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { Spacing } from '@/ui/atoms/spacing'

export const CoreListingPriceContainer = styled(Spacing)(
  ({ theme }) => `
  display: flex;
  align-items: flex-end;
  gap: ${theme.spacing.mini};
`
)

export const CoreListingPriceBadge = styled(Spacing)(
  ({ theme }) => `
  background: ${theme.colors.red.primary};
  border-radius: ${theme.borderRadius.small};
  color: ${theme.colors.white};
  display: flex;
  padding: 0.2rem ${theme.spacing.defaultSmall};
`
)

export const CoreListingPriceDropLabel = styled(BodyText)(
  ({ theme }) => `
  text-decoration: line-through;
  margin-right: ${theme.spacing.mini};
`
)

export const VerticalDivider = styled.div(
  ({ theme }) => `
    width: 0.1rem;
    height: ${theme.spacing.default};
    background: ${theme.colors.grey.light2};
`
)

export const CoreListingShippingBadge = styled(BodyText).attrs({ as: 'div' })`
  display: flex;
  gap: 0.5rem;
`
