import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgFilters: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="20.25"
        y1="9.25244"
        x2="3.75"
        y2="9.25244"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="20.25"
        y1="16.125"
        x2="3.75"
        y2="16.125"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle
        cx="2.8125"
        cy="2.8125"
        r="2.0625"
        transform="matrix(-1 0 0 1 10.875 6.2002)"
        fill="white"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <circle
        cx="2.8125"
        cy="2.8125"
        r="2.0625"
        transform="matrix(-1 0 0 1 18.75 13.2002)"
        fill="white"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </Icon>
  )
}
export default SvgFilters
