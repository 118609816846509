import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { VerticalDivider } from '@/components/shared/core-listing-card/card-details/styled'
import { BodyText } from '@/ui/atoms/body-text'

export type ListingDetailsDividerProps = {
  /**
   * Style variant of the divider
   */
  variant?: 'vertical' | 'dot'
  /**
   * Condition to render this divider between sections
   */
  visible?: boolean
}

export const ListingDetailsDivider: FC<ListingDetailsDividerProps> = ({
  variant = 'vertical',
  visible,
}) => {
  const { colors } = useTheme()

  if (!visible) return null

  if (variant === 'dot') {
    return (
      <BodyText
        color={colors.grey.light3}
        as="span"
        role="separator"
        data-testid="listing-dot-divider"
        size="xSmall"
      >
        •
      </BodyText>
    )
  }

  return <VerticalDivider role="separator" data-testid="listing-vertical-divider" />
}
