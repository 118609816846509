import { BorderRadiusKeys, ThemeProps } from '@kijiji/theme'
import { HTMLAttributes, ReactElement } from 'react'

import { ResponsiveProp } from '@/ui/typings/helpers'

import { BadgeElement } from './styled'

export enum BadgeVariant {
  LIGHT1 = 'light1',
  LIGHT2 = 'light2',
  LIGHT3 = 'light3',
}

export enum BadgeSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xLarge',
  XXLARGE = 'xxLarge',
}

export type BadgeProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * text used inside the badge
   */
  label: string | ReactElement
  /**
   * link to be used on the badge
   */
  link?: string
  /**
   * variant of the badge
   */
  variant?: BadgeVariant
  /**
   * size of the badge
   */
  size?: ResponsiveProp<BadgeSize>
  /*
   * border radius of the badge
   */
  borderRadius?: BorderRadiusKeys
  /*
   * icon to be used on the left side of the badge
   */
  leftIcon?: ReactElement
  /*
   * Defines the color of the label text
   */
  color?: string
  /*
   * Defines the color of the background
   */
  backgroundColor?: string
  /**
   * Defines font weight of the label text
   * @default 'regular'
   */
  weight?: keyof ThemeProps['fontWeight']
  /**
   * Defines the gap between icon and label
   */
  gap?: string
  /**
   * Defines if the badge should be borderless
   */
  isBorderless?: boolean
  /**
   * Defines the color of the border
   */
  borderColor?: string
}

/**
 * @description Badge to give information related to context of where ever its used.
 * It supports only text for now. In future can be adapted to hold colors, icons and backgrounds
 */

export const Badge = ({
  label,
  size = BadgeSize.MEDIUM,
  variant = BadgeVariant.LIGHT1,
  borderRadius = 'small',
  leftIcon,
  color,
  backgroundColor,
  weight = 'regular',
  gap,
  isBorderless = false,
  borderColor,
  ...rest
}: BadgeProps) => {
  return (
    <BadgeElement
      size={size}
      variant={variant}
      borderRadius={borderRadius}
      color={color}
      backgroundColor={backgroundColor}
      weight={weight}
      gap={gap}
      isBorderless={isBorderless}
      borderColor={borderColor}
      {...rest}
    >
      {leftIcon}
      {label}
    </BadgeElement>
  )
}
