import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgGrid: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 10.1V4H10.1V10.1H4ZM3 3.5C3 3.22386 3.22386 3 3.5 3H10.6C10.8761 3 11.1 3.22386 11.1 3.5V10.6C11.1 10.8761 10.8761 11.1 10.6 11.1H3.5C3.22386 11.1 3 10.8761 3 10.6V3.5ZM4 20.0001V13.9001H10.1V20.0001H4ZM3 13.4001C3 13.124 3.22386 12.9001 3.5 12.9001H10.6C10.8761 12.9001 11.1 13.124 11.1 13.4001V20.5001C11.1 20.7763 10.8761 21.0001 10.6 21.0001H3.5C3.22386 21.0001 3 20.7763 3 20.5001V13.4001ZM13.8999 4V10.1H19.9999V4H13.8999ZM13.3999 3C13.1238 3 12.8999 3.22386 12.8999 3.5V10.6C12.8999 10.8761 13.1238 11.1 13.3999 11.1H20.4999C20.776 11.1 20.9999 10.8761 20.9999 10.6V3.5C20.9999 3.22386 20.776 3 20.4999 3H13.3999ZM13.8999 20.0001V13.9001H19.9999V20.0001H13.8999ZM12.8999 13.4001C12.8999 13.124 13.1238 12.9001 13.3999 12.9001H20.4999C20.776 12.9001 20.9999 13.124 20.9999 13.4001V20.5001C20.9999 20.7763 20.776 21.0001 20.4999 21.0001H13.3999C13.1238 21.0001 12.8999 20.7763 12.8999 20.5001V13.4001Z"
      />
    </Icon>
  )
}
export default SvgGrid
