import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSubway: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="subway_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </mask>
      <g mask="url(#subway_svg__a)">
        <path
          d="M6 21v-1l1.5-1c-.983 0-1.813-.337-2.487-1.012C4.338 17.313 4 16.483 4 15.5V6c0-1.383.642-2.396 1.925-3.038C7.208 2.321 9.233 2 12 2c2.867 0 4.917.308 6.15.925C19.383 3.542 20 4.567 20 6v9.5c0 .983-.337 1.813-1.012 2.488S17.483 19 16.5 19l1.5 1v1H6zm0-11h5V7H6v3zm7 0h5V7h-5v3zm-4.5 6c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 008.5 13c-.417 0-.77.146-1.063.438A1.447 1.447 0 007 14.5c0 .417.146.77.438 1.063.291.291.645.437 1.062.437zm7 0c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0015.5 13c-.417 0-.77.146-1.063.438A1.446 1.446 0 0014 14.5c0 .417.146.77.438 1.063.291.291.645.437 1.062.437zm-8 1h9c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075V12H6v3.5c0 .433.142.792.425 1.075.283.283.642.425 1.075.425zM12 4c-1.433 0-2.62.083-3.563.25-.941.167-1.604.417-1.987.75h11.2c-.3-.333-.92-.583-1.862-.75C14.845 4.083 13.582 4 12 4z"
          fill="currentColor"
        />
      </g>
    </Icon>
  )
}
export default SvgSubway
