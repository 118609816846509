type CarMakeWikiLink = {
  [model: string]: string
}

type AutoWikiLinks = {
  [make: string]: CarMakeWikiLink[]
}

export const AUTOS_WIKIPEDIA_LINKS: AutoWikiLinks = {
  acura: [
    { cl: 'https://en.wikipedia.org/wiki/Acura_CL' },
    { csx: 'https://en.wikipedia.org/wiki/Acura_CSX' },
    { el: 'https://en.wikipedia.org/wiki/Acura_EL' },
    { integra: 'https://en.wikipedia.org/wiki/Acura_Integra' },
    { legend: 'https://en.wikipedia.org/wiki/Acura_Legend' },
    { mdx: 'https://en.wikipedia.org/wiki/Acura_MDX' },
    { nsx: 'https://en.wikipedia.org/wiki/Acura_NSX' },
    { rdx: 'https://en.wikipedia.org/wiki/Acura_RDX' },
    { rl: 'https://en.wikipedia.org/wiki/Acura_RL' },
    { rsx: 'https://en.wikipedia.org/wiki/Acura_RSX' },
    { slx: 'https://en.wikipedia.org/wiki/Acura_SLX' },
    { tl: 'https://en.wikipedia.org/wiki/Acura_TL' },
    { tsx: 'https://en.wikipedia.org/wiki/Acura_TSX' },
    { vigor: 'https://en.wikipedia.org/wiki/Acura_Vigor' },
    { zdx: 'https://en.wikipedia.org/wiki/Acura_ZDX' },
    { ilx: 'https://en.wikipedia.org/wiki/Acura_ILX' },
    { tlx: 'https://en.wikipedia.org/wiki/Acura_TLX' },
    { rlx: 'https://en.wikipedia.org/wiki/Acura_RLX' },
  ],
  alpharomeo: [
    { alfetta: 'https://en.wikipedia.org/wiki/Alfa_Romeo_Alfetta' },
    { milano: 'https://en.wikipedia.org/wiki/Alfa_Romeo_75' },
    { spider: 'https://en.wikipedia.org/wiki/Alfa_Romeo_Spider' },
    { '4c': 'https://en.wikipedia.org/wiki/Alfa_Romeo_4C' },
    { giulia: 'https://en.wikipedia.org/wiki/Alfa_Romeo_Giulia' },
    { stelvio: 'https://en.wikipedia.org/wiki/Alfa_Romeo_Stelvio' },
  ],
  amc: [{ othrmdl: 'https://en.wikipedia.org/wiki/American_Motors' }],
  am_general: [{ hummer: 'https://en.wikipedia.org/wiki/Hummer_H1' }],
  astnmrtn: [
    { db7: 'https://en.wikipedia.org/wiki/Aston_Martin_DB7' },
    { db9: 'https://en.wikipedia.org/wiki/Aston_Martin_DB9' },
    { dbs: 'https://en.wikipedia.org/wiki/Aston_Martin_DBS' },
    { vanquish: 'https://en.wikipedia.org/wiki/Aston_Martin_Vanquish' },
    { vantage: 'https://en.wikipedia.org/wiki/Aston_Martin_V8_Vantage' },
    { db11: 'https://en.wikipedia.org/wiki/Aston_Martin_DB11' },
    { rapide: 'https://en.wikipedia.org/wiki/Aston_Martin_Rapide' },
  ],
  audi: [
    { '100': 'https://en.wikipedia.org/wiki/Audi_100' },
    { '80': 'https://en.wikipedia.org/wiki/Audi_80' },
    { '90': 'https://en.wikipedia.org/wiki/Audi_90' },
    { a3: 'https://en.wikipedia.org/wiki/Audi_A3' },
    { a4: 'https://en.wikipedia.org/wiki/Audi_A4' },
    { a5: 'https://en.wikipedia.org/wiki/Audi_A5' },
    { a6: 'https://en.wikipedia.org/wiki/Audi_A6' },
    { a7: 'https://en.wikipedia.org/wiki/Audi_A7' },
    { a8: 'https://en.wikipedia.org/wiki/Audi_A8' },
    { q5: 'https://en.wikipedia.org/wiki/Audi_Q5' },
    { q7: 'https://en.wikipedia.org/wiki/Audi_Q7' },
    { quattro: 'https://en.wikipedia.org/wiki/Audi_Quattro' },
    { r8: 'https://en.wikipedia.org/wiki/Audi_R8' },
    { rs4: 'https://en.wikipedia.org/wiki/Audi_RS4' },
    { rs6: 'https://en.wikipedia.org/wiki/Audi_RS6' },
    { s3: 'https://en.wikipedia.org/wiki/Audi_S3' },
    { s4: 'https://en.wikipedia.org/wiki/Audi_S4' },
    { s5: 'https://en.wikipedia.org/wiki/Audi_S5' },
    { s6: 'https://en.wikipedia.org/wiki/Audi_S6' },
    { s8: 'https://en.wikipedia.org/wiki/Audi_S8' },
    { tt: 'https://en.wikipedia.org/wiki/Audi_TT' },
    { tt_rs: 'https://en.wikipedia.org/wiki/Audi_TT_RS' },
    { tts: 'https://en.wikipedia.org/wiki/Audi_TT' },
    { v8quat: 'https://en.wikipedia.org/wiki/Audi_V8' },
    { rs7: 'https://en.wikipedia.org/wiki/Audi_RS7' },
    { q3: 'https://en.wikipedia.org/wiki/Audi_Q3' },
    { rs5: 'https://en.wikipedia.org/wiki/Audi_RS5' },
    { s7: 'https://en.wikipedia.org/wiki/Audi_S7' },
    { rs3: 'https://en.wikipedia.org/wiki/Audi_RS3' },
    { sq5: 'https://en.wikipedia.org/wiki/Audi_SQ5' },
    { q8: 'https://en.wikipedia.org/wiki/Audi_Q8' },
    { a4_allroad: 'https://en.wikipedia.org/wiki/Audi_A4_allroad' },
    { a6_allroad: 'https://en.wikipedia.org/wiki/Audi_A6_allroad' },
    { e_tron: 'https://en.wikipedia.org/wiki/Audi_e-tron' },
  ],
  austinhealey: [
    { '100': 'https://en.wikipedia.org/wiki/Austin-Healey_100' },
    { '3000': 'https://en.wikipedia.org/wiki/Austin-Healey_3000' },
  ],
  bentley: [
    { arnage: 'https://en.wikipedia.org/wiki/Bentley_Arnage' },
    { azure: 'https://en.wikipedia.org/wiki/Bentley_Azure' },
    { brooklands: 'https://en.wikipedia.org/wiki/Bentley_Brooklands' },
    { contflyspr: 'https://en.wikipedia.org/wiki/Bentley_Flying_Spur' },
    { contgt: 'https://en.wikipedia.org/wiki/Bentley_Continental_GT' },
    { turbor: 'https://en.wikipedia.org/wiki/Bentley_Turbo_R' },
    { bentayga: 'https://en.wikipedia.org/wiki/Bentley_Bentayga' },
    { continental_gtc: 'https://en.wikipedia.org/wiki/Bentley_Continental_GT_Convertible' },
    { flying_spur: 'https://en.wikipedia.org/wiki/Bentley_Flying_Spur' },
  ],
  bmw: [
    { '1_series_m': 'https://en.wikipedia.org/wiki/BMW_1_Series_M_Coupe' },
    { '1series': 'https://en.wikipedia.org/wiki/BMW_1_Series' },
    { '3series': 'https://en.wikipedia.org/wiki/BMW_3_Series' },
    { '5series': 'https://en.wikipedia.org/wiki/BMW_5_Series' },
    { '6series': 'https://en.wikipedia.org/wiki/BMW_6_Series' },
    { '7series': 'https://en.wikipedia.org/wiki/BMW_7_Series' },
    { '8series': 'https://en.wikipedia.org/wiki/BMW_8_Series' },
    { lseries: 'https://en.wikipedia.org/wiki/BMW_L_series' },
    { m3: 'https://en.wikipedia.org/wiki/BMW_M3' },
    { m5: 'https://en.wikipedia.org/wiki/BMW_M5' },
    { m6: 'https://en.wikipedia.org/wiki/BMW_M6' },
    { mrdstcoup: 'https://en.wikipedia.org/wiki/BMW_6_Series' },
    { x1: 'https://en.wikipedia.org/wiki/BMW_X1' },
    { x3: 'https://en.wikipedia.org/wiki/BMW_X3' },
    { x5: 'https://en.wikipedia.org/wiki/BMW_X5' },
    { x6: 'https://en.wikipedia.org/wiki/BMW_X6' },
    { z3: 'https://en.wikipedia.org/wiki/BMW_Z3' },
    { z4: 'https://en.wikipedia.org/wiki/BMW_Z4' },
    { z8: 'https://en.wikipedia.org/wiki/BMW_Z8' },
    { '4series': 'https://en.wikipedia.org/wiki/BMW_4_Series' },
    { x4: 'https://en.wikipedia.org/wiki/BMW_X4' },
    { m4: 'https://en.wikipedia.org/wiki/BMW_M4' },
    { '2series': 'https://en.wikipedia.org/wiki/BMW_2_Series' },
    { m2: 'https://en.wikipedia.org/wiki/BMW_M2' },
    { x2: 'https://en.wikipedia.org/wiki/BMW_X2' },
    { x7: 'https://en.wikipedia.org/wiki/BMW_X7' },
  ],
  bricklin: [
    {
      sv_1: 'https://en.wikipedia.org/wiki/Bricklin_SV-1',
    },
  ],
  bugatti: [
    {
      veyron: 'https://en.wikipedia.org/wiki/Bugatti_Veyron',
    },
    {
      othrmdl: 'https://en.wikipedia.org/wiki/List_of_Bugatti_vehicles',
    },
  ],
  buick: [
    {
      allure: 'https://en.wikipedia.org/wiki/Buick_LaCrosse',
    },
    {
      century: 'https://en.wikipedia.org/wiki/Buick_Century',
    },
    {
      electra: 'https://en.wikipedia.org/wiki/Buick_Electra',
    },
    {
      enclave: 'https://en.wikipedia.org/wiki/Buick_Enclave',
    },
    {
      grandnat: 'https://en.wikipedia.org/wiki/Buick_Grand_National',
    },
    {
      lacrosse: 'https://en.wikipedia.org/wiki/Buick_LaCrosse',
    },
    {
      lesabre: 'https://en.wikipedia.org/wiki/Buick_LeSabre',
    },
    {
      lucerne: 'https://en.wikipedia.org/wiki/Buick_Lucerne',
    },
    {
      parkavenue: 'https://en.wikipedia.org/wiki/Buick_Park_Avenue',
    },
    {
      rainier: 'https://en.wikipedia.org/wiki/Buick_Rainier',
    },
    {
      reatta: 'https://en.wikipedia.org/wiki/Buick_Reatta',
    },
    {
      regal: 'https://en.wikipedia.org/wiki/Buick_Regal',
    },
    {
      rendezvous: 'https://en.wikipedia.org/wiki/Buick_Rendezvous',
    },
    {
      riviera: 'https://en.wikipedia.org/wiki/Buick_Riviera',
    },
    {
      roadmaster: 'https://en.wikipedia.org/wiki/Buick_Roadmaster',
    },
    {
      skylark: 'https://en.wikipedia.org/wiki/Buick_Skylark',
    },
    {
      terraza: 'https://en.wikipedia.org/wiki/Buick_Terraza',
    },
    {
      verano: 'https://en.wikipedia.org/wiki/Buick_Verano',
    },
    {
      othrmdl: 'https://en.wikipedia.org/wiki/List_of_Buick_vehicles',
    },
    {
      encore: 'https://en.wikipedia.org/wiki/Buick_Encore',
    },
    {
      envision: 'https://en.wikipedia.org/wiki/Buick_Envision',
    },
    {
      cascada: 'https://en.wikipedia.org/wiki/Opel_Cascada',
    },
  ],
  cadillac: [
    { allante: 'https://en.wikipedia.org/wiki/Cadillac_Allant%C3%A9' },
    { brougham: 'https://en.wikipedia.org/wiki/Cadillac_Brougham' },
    { catera: 'https://en.wikipedia.org/wiki/Cadillac_Catera' },
    { cimarron: 'https://en.wikipedia.org/wiki/Cadillac_Cimarron' },
    { cts: 'https://en.wikipedia.org/wiki/Cadillac_CTS' },
    { deville: 'https://en.wikipedia.org/wiki/Cadillac_DeVille' },
    { devlldts: 'https://en.wikipedia.org/wiki/Cadillac_DTS' },
    { eldorado: 'https://en.wikipedia.org/wiki/Cadillac_Eldorado' },
    { escalade: 'https://en.wikipedia.org/wiki/Cadillac_Escalade' },
    { fleetwood: 'https://en.wikipedia.org/wiki/Cadillac_Fleetwood' },
    { seville: 'https://en.wikipedia.org/wiki/Cadillac_Seville' },
    { sixspec: 'https://en.wikipedia.org/wiki/Cadillac_Sixty-Special' },
    { srx: 'https://en.wikipedia.org/wiki/Cadillac_SRX' },
    { sts: 'https://en.wikipedia.org/wiki/Cadillac_STS' },
    { xlr: 'https://en.wikipedia.org/wiki/Cadillac_XLR' },
    { ats_coupe: 'https://en.wikipedia.org/wiki/Cadillac_ATS' },
    { dts: 'https://en.wikipedia.org/wiki/Cadillac_DTS' },
    { xts: 'https://en.wikipedia.org/wiki/Cadillac_XTS' },
    { ct6: 'https://en.wikipedia.org/wiki/Cadillac_CT6' },
    { xt4: 'https://en.wikipedia.org/wiki/Cadillac_XT4' },
    { xt5: 'https://en.wikipedia.org/wiki/Cadillac_XT5' },
    { ats: 'https://en.wikipedia.org/wiki/Cadillac_ATS' },
    { xt6: 'https://en.wikipedia.org/wiki/Cadillac_XT6' },
    { elr: 'https://en.wikipedia.org/wiki/Cadillac_ELR' },
    { ct5: 'https://en.wikipedia.org/wiki/Cadillac_CT5' },
  ],
  chevrolet: [
    { astro: 'https://en.wikipedia.org/wiki/Chevrolet_Astro' },
    { avalanche: 'https://en.wikipedia.org/wiki/Chevrolet_Avalanche' },
    { aveo: 'https://en.wikipedia.org/wiki/Chevrolet_Aveo' },
    { bair150210: 'https://en.wikipedia.org/wiki/Chevrolet_C4500' },
    { beretta: 'https://en.wikipedia.org/wiki/Chevrolet_Beretta' },
    { blazer: 'https://en.wikipedia.org/wiki/Chevrolet_Blazer' },
    { c10: 'https://en.wikipedia.org/wiki/Chevrolet_C/K' },
    { camaro: 'https://en.wikipedia.org/wiki/Chevrolet_Camaro' },
    { caprice: 'https://en.wikipedia.org/wiki/Chevrolet_Caprice' },
    { cavalier: 'https://en.wikipedia.org/wiki/Chevrolet_Cavalier' },
    { chevelle: 'https://en.wikipedia.org/wiki/Chevrolet_Chevelle' },
    { chevy_van: 'https://en.wikipedia.org/wiki/Chevrolet_G-Series' },
    { cheyenne: 'https://en.wikipedia.org/wiki/Chevrolet_C/K' },
    { ckpkup1500: 'https://en.wikipedia.org/wiki/Chevrolet_C/K' },
    { ckpkup2500: 'https://en.wikipedia.org/wiki/Chevrolet_C/K' },
    { ckpkup3500: 'https://en.wikipedia.org/wiki/Chevrolet_C/K' },
    { cobalt: 'https://en.wikipedia.org/wiki/Chevrolet_Cobalt' },
    { colorado: 'https://en.wikipedia.org/wiki/Chevrolet_Colorado' },
    { corsica: 'https://en.wikipedia.org/wiki/Chevrolet_Corsica' },
    { corvair: 'https://en.wikipedia.org/wiki/Chevrolet_Corvair' },
    { corvette: 'https://en.wikipedia.org/wiki/Chevrolet_Corvette' },
    { cruze: 'https://en.wikipedia.org/wiki/Chevrolet_Cruze' },
    { cutaway_van: 'https://en.wikipedia.org/wiki/Chevrolet_G-Series' },
    { elcamino: 'https://en.wikipedia.org/wiki/Chevrolet_El_Camino' },
    { epica: 'https://en.wikipedia.org/wiki/Chevrolet_Epica' },
    { equinox: 'https://en.wikipedia.org/wiki/Chevrolet_Equinox' },
    { express: 'https://en.wikipedia.org/wiki/Chevrolet_Express' },
    { g20van: 'https://en.wikipedia.org/wiki/Chevrolet_G-Series' },
    { hhr: 'https://en.wikipedia.org/wiki/Chevrolet_HHR' },
    { impala: 'https://en.wikipedia.org/wiki/Chevrolet_Impala' },
    { lumina: 'https://en.wikipedia.org/wiki/Chevrolet_Lumina' },
    { malibu: 'https://en.wikipedia.org/wiki/Chevrolet_Malibu' },
    { metro: 'https://en.wikipedia.org/wiki/Chevrolet_Metro' },
    { montecarlo: 'https://en.wikipedia.org/wiki/Chevrolet_Monte_Carlo' },
    { nova: 'https://en.wikipedia.org/wiki/Chevrolet_Nova' },
    { optra: 'https://en.wikipedia.org/wiki/Chevrolet_Optra' },
    { orlando: 'https://en.wikipedia.org/wiki/Chevrolet_Orlando' },
    { s10: 'https://en.wikipedia.org/wiki/Chevrolet_S-10' },
    { slvrd1500: 'https://en.wikipedia.org/wiki/Chevrolet_Silverado' },
    { slvrd2500: 'https://en.wikipedia.org/wiki/Chevrolet_Silverado' },
    { slvrd3500: 'https://en.wikipedia.org/wiki/Chevrolet_Silverado' },
    { sonic: 'https://en.wikipedia.org/wiki/Chevrolet_Sonic' },
    { sprint: 'https://en.wikipedia.org/wiki/Chevrolet_Sprint' },
    { ssr: 'https://en.wikipedia.org/wiki/Chevrolet_SSR' },
    { suburban: 'https://en.wikipedia.org/wiki/Chevrolet_Suburban' },
    { t10: 'https://en.wikipedia.org/wiki/Chevrolet_T-Series' },
    { tahoe: 'https://en.wikipedia.org/wiki/Chevrolet_Tahoe' },
    { tracker: 'https://en.wikipedia.org/wiki/Chevrolet_Tracker' },
    { traverse: 'https://en.wikipedia.org/wiki/Chevrolet_Traverse' },
    { trlblzr: 'https://en.wikipedia.org/wiki/Chevrolet_TrailBlazer' },
    { uplander: 'https://en.wikipedia.org/wiki/Chevrolet_Uplander' },
    { venture: 'https://en.wikipedia.org/wiki/Chevrolet_Venture' },
    { volt: 'https://en.wikipedia.org/wiki/Chevrolet_Volt' },
    { spark: 'https://en.wikipedia.org/wiki/Chevrolet_Spark' },
    { trax: 'https://en.wikipedia.org/wiki/Chevrolet_Trax' },
    { city_express: 'https://en.wikipedia.org/wiki/Chevrolet_City_Express' },
    { bolt: 'https://en.wikipedia.org/wiki/Chevrolet_Bolt_EV' },
  ],
  chrysler: [
    { '200series': 'https://en.wikipedia.org/wiki/Chrysler_200' },
    { '300series': 'https://en.wikipedia.org/wiki/Chrysler_300' },
    { aspen: 'https://en.wikipedia.org/wiki/Chrysler_Aspen' },
    { cirrus: 'https://en.wikipedia.org/wiki/Chrysler_Cirrus' },
    { concorde: 'https://en.wikipedia.org/wiki/Chrysler_Concorde' },
    { cordoba: 'https://en.wikipedia.org/wiki/Chrysler_Cordoba' },
    { crossfire: 'https://en.wikipedia.org/wiki/Chrysler_Crossfire' },
    { imperial: 'https://en.wikipedia.org/wiki/Chrysler_Imperial' },
    { intrepid: 'https://en.wikipedia.org/wiki/Chrysler_Intrepid' },
    { lebaron: 'https://en.wikipedia.org/wiki/Chrysler_LeBaron' },
    { lhs: 'https://en.wikipedia.org/wiki/Chrysler_LHS' },
    { neon: 'https://en.wikipedia.org/wiki/Chrysler_Neon' },
    { newport: 'https://en.wikipedia.org/wiki/Chrysler_Newport' },
    { newyorker: 'https://en.wikipedia.org/wiki/Chrysler_New_Yorker' },
    { pacifica: 'https://en.wikipedia.org/wiki/Chrysler_Pacifica' },
    { prowler: 'https://en.wikipedia.org/wiki/Chrysler_Prowler' },
    { ptcruiser: 'https://en.wikipedia.org/wiki/Chrysler_PT_Cruiser' },
    { royal: 'https://en.wikipedia.org/wiki/Chrysler_Royal' },
    { sebring: 'https://en.wikipedia.org/wiki/Chrysler_Sebring' },
    { twnctry: 'https://en.wikipedia.org/wiki/Chrysler_Town_%26_Country' },
    { valiant: 'https://en.wikipedia.org/wiki/Chrysler_Valiant' },
  ],
  daewoo: [
    { lanos: 'https://en.wikipedia.org/wiki/Daewoo_Lanos' },
    { leganza: 'https://en.wikipedia.org/wiki/Daewoo_Leganza' },
    { nubira: 'https://en.wikipedia.org/wiki/Daewoo_Nubira' },
  ],
  datsun: [
    { zseries: 'https://en.wikipedia.org/wiki/Datsun_Z' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Datsun' },
  ],
  diahatsu: [
    { charade: 'https://en.wikipedia.org/wiki/Daihatsu_Charade' },
    { rocky: 'https://en.wikipedia.org/wiki/Daihatsu_Rocky' },
  ],
  dodge: [
    { avenger: 'https://en.wikipedia.org/wiki/Dodge_Avenger' },
    { caliber: 'https://en.wikipedia.org/wiki/Dodge_Caliber' },
    { caravan: 'https://en.wikipedia.org/wiki/Dodge_Caravan' },
    { challenger: 'https://en.wikipedia.org/wiki/Dodge_Challenger' },
    { charger: 'https://en.wikipedia.org/wiki/Dodge_Charger' },
    { colt: 'https://en.wikipedia.org/wiki/Dodge_Colt' },
    { coronet: 'https://en.wikipedia.org/wiki/Dodge_Coronet' },
    { dakota: 'https://en.wikipedia.org/wiki/Dodge_Dakota' },
    { dart: 'https://en.wikipedia.org/wiki/Dodge_Dart' },
    { durango: 'https://en.wikipedia.org/wiki/Dodge_Durango' },
    { grndcrvn: 'https://en.wikipedia.org/wiki/Dodge_Grand_Caravan' },
    { intrepid: 'https://en.wikipedia.org/wiki/Dodge_Intrepid' },
    { journey: 'https://en.wikipedia.org/wiki/Dodge_Journey' },
    { lancer: 'https://en.wikipedia.org/wiki/Dodge_Lancer' },
    { magnum: 'https://en.wikipedia.org/wiki/Dodge_Magnum' },
    { neon: 'https://en.wikipedia.org/wiki/Dodge_Neon' },
    { nitro: 'https://en.wikipedia.org/wiki/Dodge_Nitro' },
    { powerwagon: 'https://en.wikipedia.org/wiki/Dodge_Power_Wagon' },
    { ram1500: 'https://en.wikipedia.org/wiki/Dodge_Ram' },
    { ram2500: 'https://en.wikipedia.org/wiki/Dodge_Ram' },
    { ram3500: 'https://en.wikipedia.org/wiki/Dodge_Ram' },
    { ramvan: 'https://en.wikipedia.org/wiki/Dodge_Ram_Van' },
    { shadow: 'https://en.wikipedia.org/wiki/Dodge_Shadow' },
    { spirit: 'https://en.wikipedia.org/wiki/Dodge_Spirit' },
    { sprinter: 'https://en.wikipedia.org/wiki/Dodge_Sprinter' },
    { stealth: 'https://en.wikipedia.org/wiki/Dodge_Stealth' },
    { stratus: 'https://en.wikipedia.org/wiki/Dodge_Stratus' },
    { 'sx_2.0': 'https://en.wikipedia.org/wiki/Dodge_SX_2.0' },
    { viper: 'https://en.wikipedia.org/wiki/Dodge_Viper' },
  ],
  eagle: [
    { summit: 'https://en.wikipedia.org/wiki/Eagle_Summit' },
    { talon: 'https://en.wikipedia.org/wiki/Eagle_Talon' },
    { vision: 'https://en.wikipedia.org/wiki/Eagle_Vision' },
  ],
  ferrari: [
    { '308': 'https://en.wikipedia.org/wiki/Ferrari_308' },
    { '328': 'https://en.wikipedia.org/wiki/Ferrari_328' },
    { '348': 'https://en.wikipedia.org/wiki/Ferrari_348' },
    { '360': 'https://en.wikipedia.org/wiki/Ferrari_360' },
    { '430': 'https://en.wikipedia.org/wiki/Ferrari_F430' },
    { '456': 'https://en.wikipedia.org/wiki/Ferrari_456' },
    { '550': 'https://en.wikipedia.org/wiki/Ferrari_550_Maranello' },
    { '575': 'https://en.wikipedia.org/wiki/Ferrari_575_Maranello' },
    { mondial: 'https://en.wikipedia.org/wiki/Ferrari_Mondial' },
    { testarossa: 'https://en.wikipedia.org/wiki/Ferrari_Testarossa' },
    { '458': 'https://en.wikipedia.org/wiki/Ferrari_458' },
    { '599_gtb': 'https://en.wikipedia.org/wiki/Ferrari_599_GT_Berlinetta' },
    { california: 'https://en.wikipedia.org/wiki/Ferrari_California' },
    { f12: 'https://en.wikipedia.org/wiki/Ferrari_F12berlinetta' },
    { f355: 'https://en.wikipedia.org/wiki/Ferrari_F355' },
  ],
  fiat: [
    { '500c': 'https://en.wikipedia.org/wiki/Fiat_500C' },
    { '124_spider': 'https://en.wikipedia.org/wiki/Fiat_124_Spider' },
    { '500l': 'https://en.wikipedia.org/wiki/Fiat_500L' },
    { '500x': 'https://en.wikipedia.org/wiki/Fiat_500X' },
  ],
  ford: [
    { aerostar: 'https://en.wikipedia.org/wiki/Ford_Aerostar' },
    { aspire: 'https://en.wikipedia.org/wiki/Ford_Aspire' },
    { bronco: 'https://en.wikipedia.org/wiki/Ford_Bronco' },
    { broncoii: 'https://en.wikipedia.org/wiki/Ford_Bronco_II' },
    { club_wagon: 'https://en.wikipedia.org/wiki/Ford_Club_Wagon' },
    { contour: 'https://en.wikipedia.org/wiki/Ford_Contour' },
    { cougar: 'https://en.wikipedia.org/wiki/Ford_Cougar' },
    { crwnvctr: 'https://en.wikipedia.org/wiki/Ford_Crown_Victoria' },
    { 'e-150': 'https://en.wikipedia.org/wiki/Ford_E-Series' },
    { 'e-250': 'https://en.wikipedia.org/wiki/Ford_E-Series' },
    { 'e-350': 'https://en.wikipedia.org/wiki/Ford_E-Series' },
    { edge: 'https://en.wikipedia.org/wiki/Ford_Edge' },
    { escape: 'https://en.wikipedia.org/wiki/Ford_Escape' },
    { escort: 'https://en.wikipedia.org/wiki/Ford_Escort' },
    { esrsvan: 'https://en.wikipedia.org/wiki/Ford_Econoline' },
    { excursion: 'https://en.wikipedia.org/wiki/Ford_Excursion' },
    { expedition: 'https://en.wikipedia.org/wiki/Ford_Expedition' },
    { explorer: 'https://en.wikipedia.org/wiki/Ford_Explorer' },
    { explorer_sport: 'https://en.wikipedia.org/wiki/Ford_Explorer_Sport' },
    { f100: 'https://en.wikipedia.org/wiki/Ford_F-Series_(ninth_generation)#Ford_F-100' },
    { f150: 'https://en.wikipedia.org/wiki/Ford_F-Series' },
    { f250: 'https://en.wikipedia.org/wiki/Ford_F-Series' },
    { f350: 'https://en.wikipedia.org/wiki/Ford_F-Series' },
    { f450: 'https://en.wikipedia.org/wiki/Ford_F-Series' },
    { f550: 'https://en.wikipedia.org/wiki/Ford_F-Series' },
    { f650: 'https://en.wikipedia.org/wiki/Ford_F-Series' },
    { f750: 'https://en.wikipedia.org/wiki/Ford_F-Series' },
    { f800: 'https://en.wikipedia.org/wiki/Ford_F-Series' },
    { fairlane: 'https://en.wikipedia.org/wiki/Ford_Fairlane' },
    { fairmont: 'https://en.wikipedia.org/wiki/Ford_Fairmont' },
    { falcon: 'https://en.wikipedia.org/wiki/Ford_Falcon' },
    { fiesta: 'https://en.wikipedia.org/wiki/Ford_Fiesta' },
    { flex: 'https://en.wikipedia.org/wiki/Ford_Flex' },
    { focus: 'https://en.wikipedia.org/wiki/Ford_Focus' },
    { freestar: 'https://en.wikipedia.org/wiki/Ford_Freestar' },
    { frstltrsx: 'https://en.wikipedia.org/wiki/Ford_First_Look' },
    { fusion: 'https://en.wikipedia.org/wiki/Ford_Fusion' },
    { fvhndrd: 'https://en.wikipedia.org/wiki/Ford_Five_Hundred' },
    { galaxie: 'https://en.wikipedia.org/wiki/Ford_Galaxie' },
    { grand_marquis: 'https://en.wikipedia.org/wiki/Mercury_Grand_Marquis' },
    { gt: 'https://en.wikipedia.org/wiki/Ford_GT' },
    { marauder: 'https://en.wikipedia.org/wiki/Mercury_Marauder' },
    { modela: 'https://en.wikipedia.org/wiki/Ford_Model_A' },
    { modelt: 'https://en.wikipedia.org/wiki/Ford_Model_T' },
    { mustang: 'https://en.wikipedia.org/wiki/Ford_Mustang' },
    { probe: 'https://en.wikipedia.org/wiki/Ford_Probe' },
    { ranchero: 'https://en.wikipedia.org/wiki/Ford_Ranchero' },
    { ranger: 'https://en.wikipedia.org/wiki/Ford_Ranger' },
    { taurus: 'https://en.wikipedia.org/wiki/Ford_Taurus' },
    { tempo: 'https://en.wikipedia.org/wiki/Ford_Tempo' },
    { thndrbrd: 'https://en.wikipedia.org/wiki/Ford_Thunderbird' },
    { torino: 'https://en.wikipedia.org/wiki/Ford_Torino' },
    { transit_connect: 'https://en.wikipedia.org/wiki/Ford_Transit_Connect' },
    { windstar: 'https://en.wikipedia.org/wiki/Ford_Windstar' },
    { xplrsprttrc: 'https://en.wikipedia.org/wiki/Ford_Explorer_Sport_Trac' },
    { maverick: 'https://en.wikipedia.org/wiki/Ford_Maverick' },
    { 'c-max': 'https://en.wikipedia.org/wiki/Ford_C-Max' },
    { 'f-150_raptor': 'https://en.wikipedia.org/wiki/Ford_F-150_Raptor' },
    { eco_sport: 'https://en.wikipedia.org/wiki/Ford_EcoSport' },
    { transit: 'https://en.wikipedia.org/wiki/Ford_Transit' },
    { 'e-450': 'https://en.wikipedia.org/wiki/Ford_E-Series' },
  ],
  genesis: [
    { g80: 'https://en.wikipedia.org/wiki/Genesis_G80' },
    { g90: 'https://en.wikipedia.org/wiki/Genesis_G90' },
    { g70: 'https://en.wikipedia.org/wiki/Genesis_G70' },
  ],
  geo: [
    { metro: 'https://en.wikipedia.org/wiki/Geo_Metro' },
    { tracker: 'https://en.wikipedia.org/wiki/Geo_Tracker' },
  ],
  gmc: [
    { acadia: 'https://en.wikipedia.org/wiki/GMC_Acadia' },
    { canyon: 'https://en.wikipedia.org/wiki/GMC_Canyon' },
    { ck1500: 'https://en.wikipedia.org/wiki/GMC_C/K' },
    { ck2500: 'https://en.wikipedia.org/wiki/GMC_C/K' },
    { ck3500: 'https://en.wikipedia.org/wiki/GMC_C/K' },
    { envoy: 'https://en.wikipedia.org/wiki/GMC_Envoy' },
    { jimmy: 'https://en.wikipedia.org/wiki/GMC_Jimmy' },
    { rally_van: 'https://en.wikipedia.org/wiki/GMC_Rally' },
    { safari: 'https://en.wikipedia.org/wiki/GMC_Safari' },
    { savana: 'https://en.wikipedia.org/wiki/GMC_Savana' },
    { sierra1500: 'https://en.wikipedia.org/wiki/GMC_Sierra' },
    { sierra2500: 'https://en.wikipedia.org/wiki/GMC_Sierra' },
    { sierra3500: 'https://en.wikipedia.org/wiki/GMC_Sierra' },
    { sonoma: 'https://en.wikipedia.org/wiki/GMC_Sonoma' },
    { suburban: 'https://en.wikipedia.org/wiki/Chevrolet_Suburban' },
    { terrain: 'https://en.wikipedia.org/wiki/GMC_Terrain' },
    { typhoon: 'https://en.wikipedia.org/wiki/GMC_Typhoon' },
    { vandura: 'https://en.wikipedia.org/wiki/GMC_Vandura' },
    { yukon: 'https://en.wikipedia.org/wiki/GMC_Yukon' },
  ],
  honda: [
    { accord: 'https://en.wikipedia.org/wiki/Honda_Accord' },
    { accord_crosstour: 'https://en.wikipedia.org/wiki/Honda_Accord_Crosstour' },
    { civic: 'https://en.wikipedia.org/wiki/Honda_Civic' },
    { 'cr-z': 'https://en.wikipedia.org/wiki/Honda_CR-Z' },
    { crv: 'https://en.wikipedia.org/wiki/Honda_CR-V' },
    { crx: 'https://en.wikipedia.org/wiki/Honda_CR-X' },
    { delsol: 'https://en.wikipedia.org/wiki/Honda_CR-X_Del_Sol' },
    { element: 'https://en.wikipedia.org/wiki/Honda_Element' },
    { fit: 'https://en.wikipedia.org/wiki/Honda_Fit' },
    { insight: 'https://en.wikipedia.org/wiki/Honda_Insight' },
    { odyssey: 'https://en.wikipedia.org/wiki/Honda_Odyssey' },
    { passport: 'https://en.wikipedia.org/wiki/Honda_Passport' },
    { pilot: 'https://en.wikipedia.org/wiki/Honda_Pilot' },
    { prelude: 'https://en.wikipedia.org/wiki/Honda_Prelude' },
    { ridgeline: 'https://en.wikipedia.org/wiki/Honda_Ridgeline' },
    { s2000: 'https://en.wikipedia.org/wiki/Honda_S2000' },
    { hrv: 'https://en.wikipedia.org/wiki/Honda_HR-V' },
    { crosstour: 'https://en.wikipedia.org/wiki/Honda_Crosstour' },
  ],
  hummer: [
    { h1: 'https://en.wikipedia.org/wiki/Hummer_H1' },
    { h2: 'https://en.wikipedia.org/wiki/Hummer_H2' },
    { h3: 'https://en.wikipedia.org/wiki/Hummer_H3' },
    { h3t: 'https://en.wikipedia.org/wiki/Hummer_H3T' },
  ],
  hyundai: [
    { accent: 'https://en.wikipedia.org/wiki/Hyundai_Accent' },
    { azera: 'https://en.wikipedia.org/wiki/Hyundai_Azera' },
    { elantra: 'https://en.wikipedia.org/wiki/Hyundai_Elantra' },
    { entourage: 'https://en.wikipedia.org/wiki/Hyundai_Entourage' },
    { equus: 'https://en.wikipedia.org/wiki/Hyundai_Equus' },
    { genesis: 'https://en.wikipedia.org/wiki/Hyundai_Genesis' },
    { genesis_coupe: 'https://en.wikipedia.org/wiki/Hyundai_Genesis_Coupe' },
    { santafe: 'https://en.wikipedia.org/wiki/Hyundai_Santa_Fe' },
    { sonata: 'https://en.wikipedia.org/wiki/Hyundai_Sonata' },
    { sonata_hybrid: 'https://en.wikipedia.org/wiki/Hyundai_Sonata_Hybrid' },
    { tiburon: 'https://en.wikipedia.org/wiki/Hyundai_Tiburon' },
    { tucson: 'https://en.wikipedia.org/wiki/Hyundai_Tucson' },
    { veloster: 'https://en.wikipedia.org/wiki/Hyundai_Veloster' },
    { veracruz: 'https://en.wikipedia.org/wiki/Hyundai_Veracruz' },
    { xg300: 'https://en.wikipedia.org/wiki/Hyundai_XG' },
    { xg350: 'https://en.wikipedia.org/wiki/Hyundai_XG' },
    { ioniq: 'https://en.wikipedia.org/wiki/Hyundai_Ioniq' },
    { kona: 'https://en.wikipedia.org/wiki/Hyundai_Kona' },
    { nexo: 'https://en.wikipedia.org/wiki/Hyundai_Nexo' },
    { palisade: 'https://en.wikipedia.org/wiki/Hyundai_Palisade' },
    { venue: 'https://en.wikipedia.org/wiki/Hyundai_Venue' },
  ],
  infiniti: [
    { ex: 'https://en.wikipedia.org/wiki/Infiniti_EX' },
    { fx: 'https://en.wikipedia.org/wiki/Infiniti_FX' },
    { g20: 'https://en.wikipedia.org/wiki/Infiniti_G20' },
    { g25: 'https://en.wikipedia.org/wiki/Infiniti_G25' },
    { g25x: 'https://en.wikipedia.org/wiki/Infiniti_G25' },
    { g35: 'https://en.wikipedia.org/wiki/Infiniti_G35' },
    { g35x: 'https://en.wikipedia.org/wiki/Infiniti_G35' },
    { g37: 'https://en.wikipedia.org/wiki/Infiniti_G37' },
    { g37x: 'https://en.wikipedia.org/wiki/Infiniti_G37' },
    { i30: 'https://en.wikipedia.org/wiki/Infiniti_I30' },
    { i35: 'https://en.wikipedia.org/wiki/Infiniti_I35' },
    { j30: 'https://en.wikipedia.org/wiki/Infiniti_J30' },
    { m: 'https://en.wikipedia.org/wiki/Infiniti_M' },
    { m35: 'https://en.wikipedia.org/wiki/Infiniti_M35' },
    { m35h: 'https://en.wikipedia.org/wiki/Infiniti_M35h' },
    { m37: 'https://en.wikipedia.org/wiki/Infiniti_M37' },
    { m37x: 'https://en.wikipedia.org/wiki/Infiniti_M37' },
    { m45: 'https://en.wikipedia.org/wiki/Infiniti_M45' },
    { m56: 'https://en.wikipedia.org/wiki/Infiniti_M56' },
    { q45: 'https://en.wikipedia.org/wiki/Infiniti_Q45' },
    { qx4: 'https://en.wikipedia.org/wiki/Infiniti_QX4' },
    { qx56: 'https://en.wikipedia.org/wiki/Infiniti_QX56' },
    { g38: 'https://en.wikipedia.org/wiki/Infiniti_G37' },
    { jx: 'https://en.wikipedia.org/wiki/Infiniti_JX' },
    { qx60: 'https://en.wikipedia.org/wiki/Infiniti_QX60' },
    { qx80: 'https://en.wikipedia.org/wiki/Infiniti_QX80' },
    { q50: 'https://en.wikipedia.org/wiki/Infiniti_Q50' },
    { qx50: 'https://en.wikipedia.org/wiki/Infiniti_QX50' },
    { q60: 'https://en.wikipedia.org/wiki/Infiniti_Q60' },
    { q70: 'https://en.wikipedia.org/wiki/Infiniti_Q70' },
    { qx30: 'https://en.wikipedia.org/wiki/Infiniti_QX30' },
    { qx70: 'https://en.wikipedia.org/wiki/Infiniti_QX70' },
    { ex37: 'https://en.wikipedia.org/wiki/Infiniti_EX37' },
  ],
  inthrvstr: [{ scout: 'https://en.wikipedia.org/wiki/International_Scout' }],
  isuzu: [
    { amigo: 'https://en.wikipedia.org/wiki/Isuzu_Amigo' },
    { hombre: 'https://en.wikipedia.org/wiki/Isuzu_Hombre' },
    { rodeo: 'https://en.wikipedia.org/wiki/Isuzu_Rodeo' },
    { trooper: 'https://en.wikipedia.org/wiki/Isuzu_Trooper' },
    { vehicross: 'https://en.wikipedia.org/wiki/Isuzu_VehiCROSS' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Isuzu' },
  ],
  jaguar: [
    { etype: 'https://en.wikipedia.org/wiki/Jaguar_E-type' },
    { stype: 'https://en.wikipedia.org/wiki/Jaguar_S-type' },
    { xf: 'https://en.wikipedia.org/wiki/Jaguar_XF' },
    { xj: 'https://en.wikipedia.org/wiki/Jaguar_XJ' },
    { xj12: 'https://en.wikipedia.org/wiki/Jaguar_XJ12' },
    { xj6: 'https://en.wikipedia.org/wiki/Jaguar_XJ6' },
    { xj8: 'https://en.wikipedia.org/wiki/Jaguar_XJ8' },
    { xjr: 'https://en.wikipedia.org/wiki/Jaguar_XJR' },
    { xjs: 'https://en.wikipedia.org/wiki/Jaguar_XJS' },
    { xk: 'https://en.wikipedia.org/wiki/Jaguar_XK' },
    { xk8: 'https://en.wikipedia.org/wiki/Jaguar_XK8' },
    { xkr: 'https://en.wikipedia.org/wiki/Jaguar_XKR' },
    { xtype: 'https://en.wikipedia.org/wiki/Jaguar_X-Type' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Jaguar' },
    { e_pace: 'https://en.wikipedia.org/wiki/Jaguar_E-Pace' },
    { f_pace: 'https://en.wikipedia.org/wiki/Jaguar_F-Pace' },
    { f_type: 'https://en.wikipedia.org/wiki/Jaguar_F-Type' },
    { i_pace: 'https://en.wikipedia.org/wiki/Jaguar_I-Pace' },
    { xe: 'https://en.wikipedia.org/wiki/Jaguar_XE' },
  ],
  jeep: [
    { cherokee: 'https://en.wikipedia.org/wiki/Jeep_Cherokee' },
    { cj: 'https://en.wikipedia.org/wiki/Jeep_CJ' },
    { commander: 'https://en.wikipedia.org/wiki/Jeep_Commander' },
    { commando: 'https://en.wikipedia.org/wiki/Jeep_Commando' },
    { compass: 'https://en.wikipedia.org/wiki/Jeep_Compass' },
    { grndchrk: 'https://en.wikipedia.org/wiki/Jeep_Grand_Cherokee' },
    { liberty: 'https://en.wikipedia.org/wiki/Jeep_Liberty' },
    { patriot: 'https://en.wikipedia.org/wiki/Jeep_Patriot' },
    { renegade: 'https://en.wikipedia.org/wiki/Jeep_Renegade' },
    { tj: 'https://en.wikipedia.org/wiki/Jeep_Wrangler#TJ' },
    { wagoneer: 'https://en.wikipedia.org/wiki/Jeep_Wagoneer' },
    { wrangler: 'https://en.wikipedia.org/wiki/Jeep_Wrangler' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Jeep' },
    { gladiator: 'https://en.wikipedia.org/wiki/Jeep_Gladiator_(JT)' },
  ],
  kia: [
    { amanti: 'https://en.wikipedia.org/wiki/Kia_Amanti' },
    { borrego: 'https://en.wikipedia.org/wiki/Kia_Borrego' },
    { forte: 'https://en.wikipedia.org/wiki/Kia_Forte' },
    { forte5: 'https://en.wikipedia.org/wiki/Kia_Forte' },
    { magentis: 'https://en.wikipedia.org/wiki/Kia_Magentis' },
    { optima: 'https://en.wikipedia.org/wiki/Kia_Optima' },
    { rio: 'https://en.wikipedia.org/wiki/Kia_Rio' },
    { rondo: 'https://en.wikipedia.org/wiki/Kia_Rondo' },
    { sedona: 'https://en.wikipedia.org/wiki/Kia_Sedona' },
    { sephia: 'https://en.wikipedia.org/wiki/Kia_Sephia' },
    { sorento: 'https://en.wikipedia.org/wiki/Kia_Sorento' },
    { soul: 'https://en.wikipedia.org/wiki/Kia_Soul' },
    { spectra: 'https://en.wikipedia.org/wiki/Kia_Spectra' },
    { sportage: 'https://en.wikipedia.org/wiki/Kia_Sportage' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Kia' },
    { cadenza: 'https://en.wikipedia.org/wiki/Kia_Cadenza' },
    { k900: 'https://en.wikipedia.org/wiki/Kia_K900' },
    { niro: 'https://en.wikipedia.org/wiki/Kia_Niro' },
    { stinger: 'https://en.wikipedia.org/wiki/Kia_Stinger' },
    { telluride: 'https://en.wikipedia.org/wiki/Kia_Telluride' },
    { xceed: 'https://en.wikipedia.org/wiki/Kia_Xceed' },
  ],
  landrover: [
    { defender: 'https://en.wikipedia.org/wiki/Land_Rover_Defender' },
    { discovery: 'https://en.wikipedia.org/wiki/Land_Rover_Discovery' },
    { freelander: 'https://en.wikipedia.org/wiki/Land_Rover_Freelander' },
    { lr2: 'https://en.wikipedia.org/wiki/Land_Rover_LR2' },
    { lr3: 'https://en.wikipedia.org/wiki/Land_Rover_LR3' },
    { lr4: 'https://en.wikipedia.org/wiki/Land_Rover_LR4' },
    { range_rover_evoque: 'https://en.wikipedia.org/wiki/Range_Rover_Evoque' },
    { rngrvr: 'https://en.wikipedia.org/wiki/Range_Rover' },
    { rngrvrsprt: 'https://en.wikipedia.org/wiki/Range_Rover_Sport' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Land_Rover' },
    { discovery_sport: 'https://en.wikipedia.org/wiki/Land_Rover_Discovery_Sport' },
    { range_rover_velar: 'https://en.wikipedia.org/wiki/Range_Rover_Velar' },
  ],
  lexus: [
    { ct_200h: 'https://en.wikipedia.org/wiki/Lexus_CT' },
    { es: 'https://en.wikipedia.org/wiki/Lexus_ES' },
    { gs: 'https://en.wikipedia.org/wiki/Lexus_GS' },
    { gx: 'https://en.wikipedia.org/wiki/Lexus_GX' },
    { hs_250h: 'https://en.wikipedia.org/wiki/Lexus_HS' },
    { is: 'https://en.wikipedia.org/wiki/Lexus_IS' },
    { is_350c: 'https://en.wikipedia.org/wiki/Lexus_IS' },
    { lfa: 'https://en.wikipedia.org/wiki/Lexus_LFA' },
    { ls: 'https://en.wikipedia.org/wiki/Lexus_LS' },
    { lx: 'https://en.wikipedia.org/wiki/Lexus_LX' },
    { rx: 'https://en.wikipedia.org/wiki/Lexus_RX' },
    { sc: 'https://en.wikipedia.org/wiki/Lexus_SC' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Lexus' },
    { nx_200t: 'https://en.wikipedia.org/wiki/Lexus_NX' },
    { rc_350: 'https://en.wikipedia.org/wiki/Lexus_RC' },
    { nx_300h: 'https://en.wikipedia.org/wiki/Lexus_NX' },
  ],
  lincoln: [
    { aviator: 'https://en.wikipedia.org/wiki/Lincoln_Aviator' },
    { cntntal: 'https://en.wikipedia.org/wiki/Lincoln_Continental' },
    { ls: 'https://en.wikipedia.org/wiki/Lincoln_LS' },
    { markseries: 'https://en.wikipedia.org/wiki/Lincoln_Mark' },
    { mks: 'https://en.wikipedia.org/wiki/Lincoln_MKS' },
    { mkt: 'https://en.wikipedia.org/wiki/Lincoln_MKT' },
    { mkx: 'https://en.wikipedia.org/wiki/Lincoln_MKX' },
    { mkz: 'https://en.wikipedia.org/wiki/Lincoln_MKZ' },
    { navigator: 'https://en.wikipedia.org/wiki/Lincoln_Navigator' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Lincoln' },
    { corsair: 'https://en.wikipedia.org/wiki/Lincoln_Corsair' },
  ],
  mazda: [
    { '323': 'https://en.wikipedia.org/wiki/Mazda_323' },
    { '626': 'https://en.wikipedia.org/wiki/Mazda_626' },
    { '929': 'https://en.wikipedia.org/wiki/Mazda_929' },
    { bsrspckps: 'https://en.wikipedia.org/wiki/Mazda_B-series' },
    { cx5: 'https://en.wikipedia.org/wiki/Mazda_CX-5' },
    { cx7: 'https://en.wikipedia.org/wiki/Mazda_CX-7' },
    { cx9: 'https://en.wikipedia.org/wiki/Mazda_CX-9' },
    { mazda3_sport: 'https://en.wikipedia.org/wiki/Mazda3' },
    { mazda6_sport: 'https://en.wikipedia.org/wiki/Mazda6' },
    { mazdaspeed3: 'https://en.wikipedia.org/wiki/Mazda_Mazdaspeed3' },
    { mazdaspeed6: 'https://en.wikipedia.org/wiki/Mazda_Mazdaspeed6' },
    { 'mazdaspeed_mx-5_miata': 'https://en.wikipedia.org/wiki/Mazda_Mazdaspeed_MX-5' },
    { mazdaspeed_protege: 'https://en.wikipedia.org/wiki/Mazda_Mazdaspeed_Protege' },
    { millenia: 'https://en.wikipedia.org/wiki/Mazda_Millenia' },
    { mpv: 'https://en.wikipedia.org/wiki/Mazda_MPV' },
    { mx3: 'https://en.wikipedia.org/wiki/Mazda_MX-3' },
    { mx5miata: 'https://en.wikipedia.org/wiki/Mazda_MX-5' },
    { mx6: 'https://en.wikipedia.org/wiki/Mazda_MX-6' },
    { protege: 'https://en.wikipedia.org/wiki/Mazda_Protegé' },
    { rx7: 'https://en.wikipedia.org/wiki/Mazda_RX-7' },
    { rx8: 'https://en.wikipedia.org/wiki/Mazda_RX-8' },
    { tribute: 'https://en.wikipedia.org/wiki/Mazda_Tribute' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Mazda' },
    { cx3: 'https://en.wikipedia.org/wiki/Mazda_CX-3' },
    { '2': 'https://en.wikipedia.org/wiki/Mazda_2' },
    { '3': 'https://en.wikipedia.org/wiki/Mazda3' },
    { '5': 'https://en.wikipedia.org/wiki/Mazda_5' },
    { '6': 'https://en.wikipedia.org/wiki/Mazda6' },
    { 'cx-30': 'https://en.wikipedia.org/wiki/Mazda_CX-30' },
  ],
  mitsubishi: [
    { '3000gt': 'https://en.wikipedia.org/wiki/Mitsubishi_3000GT' },
    { eclipse: 'https://en.wikipedia.org/wiki/Mitsubishi_Eclipse' },
    { galant: 'https://en.wikipedia.org/wiki/Mitsubishi_Galant' },
    { lancer: 'https://en.wikipedia.org/wiki/Mitsubishi_Lancer' },
    { outlander: 'https://en.wikipedia.org/wiki/Mitsubishi_Outlander' },
    { rvr: 'https://en.wikipedia.org/wiki/Mitsubishi_RVR' },
    { montero: 'https://en.wikipedia.org/wiki/Mitsubishi_Montero' },
    { pajero: 'https://en.wikipedia.org/wiki/Mitsubishi_Pajero' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Mitsubishi' },
    { eclipse_cross: 'https://en.wikipedia.org/wiki/Mitsubishi_Eclipse_Cross' },
  ],
  nissan: [
    { '200sx': 'https://en.wikipedia.org/wiki/Nissan_200SX' },
    { '240sx': 'https://en.wikipedia.org/wiki/Nissan_240SX' },
    { '280zx': 'https://en.wikipedia.org/wiki/Nissan_280ZX' },
    { '300zx': 'https://en.wikipedia.org/wiki/Nissan_300ZX' },
    { '350z': 'https://en.wikipedia.org/wiki/Nissan_350Z' },
    { '370z': 'https://en.wikipedia.org/wiki/Nissan_370Z' },
    { altima: 'https://en.wikipedia.org/wiki/Nissan_Altima' },
    { armada: 'https://en.wikipedia.org/wiki/Nissan_Armada' },
    { cube: 'https://en.wikipedia.org/wiki/Nissan_Cube' },
    { frontier: 'https://en.wikipedia.org/wiki/Nissan_Frontier' },
    { gtr: 'https://en.wikipedia.org/wiki/Nissan_GT-R' },
    { hardbody: 'https://en.wikipedia.org/wiki/Nissan_Hardbody' },
    { juke: 'https://en.wikipedia.org/wiki/Nissan_Juke' },
    { maxima: 'https://en.wikipedia.org/wiki/Nissan_Maxima' },
    { murano: 'https://en.wikipedia.org/wiki/Nissan_Murano' },
    { nv_1500: 'https://en.wikipedia.org/wiki/Nissan_NV' },
    { nv_2500: 'https://en.wikipedia.org/wiki/Nissan_NV' },
    { nv_3500: 'https://en.wikipedia.org/wiki/Nissan_NV' },
    { pathfinder: 'https://en.wikipedia.org/wiki/Nissan_Pathfinder' },
    { pulsar: 'https://en.wikipedia.org/wiki/Nissan_Pulsar' },
    { quest: 'https://en.wikipedia.org/wiki/Nissan_Quest' },
    { rogue: 'https://en.wikipedia.org/wiki/Nissan_Rogue' },
    { sentra: 'https://en.wikipedia.org/wiki/Nissan_Sentra' },
    { skyline: 'https://en.wikipedia.org/wiki/Nissan_Skyline' },
    { stanza: 'https://en.wikipedia.org/wiki/Nissan_Stanza' },
    { titan: 'https://en.wikipedia.org/wiki/Nissan_Titan' },
    { versa: 'https://en.wikipedia.org/wiki/Nissan_Versa' },
    { xterra: 'https://en.wikipedia.org/wiki/Nissan_Xterra' },
    { xtrail: 'https://en.wikipedia.org/wiki/Nissan_X-Trail' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Nissan' },
    { othrpkups: 'https://en.wikipedia.org/wiki/Nissan' },
    { leaf: 'https://en.wikipedia.org/wiki/Nissan_Leaf' },
    { micra: 'https://en.wikipedia.org/wiki/Nissan_Micra' },
    { qashqai: 'https://en.wikipedia.org/wiki/Nissan_Qashqai' },
    { kicks: 'https://en.wikipedia.org/wiki/Nissan_Kicks' },
    { nv200: 'https://en.wikipedia.org/wiki/Nissan_NV200' },
  ],
  oldsmobile: [
    { '442': 'https://en.wikipedia.org/wiki/Oldsmobile_442' },
    { '88': 'https://en.wikipedia.org/wiki/Oldsmobile_88' },
    { '98': 'https://en.wikipedia.org/wiki/Oldsmobile_98' },
    { achieva: 'https://en.wikipedia.org/wiki/Oldsmobile_Achieva' },
    { alero: 'https://en.wikipedia.org/wiki/Oldsmobile_Alero' },
    { aurora: 'https://en.wikipedia.org/wiki/Oldsmobile_Aurora' },
    { bravada: 'https://en.wikipedia.org/wiki/Oldsmobile_Bravada' },
    { cutlass: 'https://en.wikipedia.org/wiki/Oldsmobile_Cutlass' },
    { intrigue: 'https://en.wikipedia.org/wiki/Oldsmobile_Intrigue' },
    { lss: 'https://en.wikipedia.org/wiki/Oldsmobile_LSS' },
    { silhouette: 'https://en.wikipedia.org/wiki/Oldsmobile_Silhouette' },
    { toronado: 'https://en.wikipedia.org/wiki/Oldsmobile_Toronado' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Oldsmobile' },
  ],
  opel: [
    { gt: 'https://en.wikipedia.org/wiki/Opel_GT' },
    { kadett: 'https://en.wikipedia.org/wiki/Opel_Kadett' },
    { manta: 'https://en.wikipedia.org/wiki/Opel_Manta' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Opel' },
  ],
  othrmake: [{ othrmdl: 'https://en.wikipedia.org/wiki/Other' }],
  peugeot: [
    { '309': 'https://en.wikipedia.org/wiki/Peugeot_309' },
    { '403': 'https://en.wikipedia.org/wiki/Peugeot_403' },
    { '404': 'https://en.wikipedia.org/wiki/Peugeot_404' },
    { '405': 'https://en.wikipedia.org/wiki/Peugeot_405' },
    { '504': 'https://en.wikipedia.org/wiki/Peugeot_504' },
    { '505': 'https://en.wikipedia.org/wiki/Peugeot_505' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Peugeot' },
  ],
  plymouth: [
    { acclaim: 'https://en.wikipedia.org/wiki/Plymouth_Acclaim' },
    { barracuda: 'https://en.wikipedia.org/wiki/Plymouth_Barracuda' },
    { breeze: 'https://en.wikipedia.org/wiki/Plymouth_Breeze' },
    { colt: 'https://en.wikipedia.org/wiki/Plymouth_Colt' },
    { duster: 'https://en.wikipedia.org/wiki/Plymouth_Duster' },
    { fury: 'https://en.wikipedia.org/wiki/Plymouth_Fury' },
    { grndvygr: 'https://en.wikipedia.org/wiki/Plymouth_Grand_Voyager' },
    { gtx: 'https://en.wikipedia.org/wiki/Plymouth_GTX' },
    { neon: 'https://en.wikipedia.org/wiki/Plymouth_Neon' },
    { prowler: 'https://en.wikipedia.org/wiki/Plymouth_Prowler' },
    { roadrunner: 'https://en.wikipedia.org/wiki/Plymouth_Road_Runner' },
    { satellite: 'https://en.wikipedia.org/wiki/Plymouth_Satellite' },
    { scamp: 'https://en.wikipedia.org/wiki/Plymouth_Scamp' },
    { sundance: 'https://en.wikipedia.org/wiki/Plymouth_Sundance' },
    { voyager: 'https://en.wikipedia.org/wiki/Plymouth_Voyager' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Plymouth' },
  ],
  polestar: [
    { '1': 'https://en.wikipedia.org/wiki/Polestar_1' },
    { '2': 'https://en.wikipedia.org/wiki/Polestar_2' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Polestar' },
  ],
  pontiac: [
    { aztek: 'https://en.wikipedia.org/wiki/Pontiac_Aztek' },
    { bonneville: 'https://en.wikipedia.org/wiki/Pontiac_Bonneville' },
    { catalina: 'https://en.wikipedia.org/wiki/Pontiac_Catalina' },
    { fiero: 'https://en.wikipedia.org/wiki/Pontiac_Fiero' },
    { firebird: 'https://en.wikipedia.org/wiki/Pontiac_Firebird' },
    { firefly: 'https://en.wikipedia.org/wiki/Pontiac_Firefly' },
    { g3: 'https://en.wikipedia.org/wiki/Pontiac_G3' },
    { g3_wave: 'https://en.wikipedia.org/wiki/Pontiac_G3_Wave' },
    { g5: 'https://en.wikipedia.org/wiki/Pontiac_G5' },
    { g6: 'https://en.wikipedia.org/wiki/Pontiac_G6' },
    { g8: 'https://en.wikipedia.org/wiki/Pontiac_G8' },
    { grandam: 'https://en.wikipedia.org/wiki/Pontiac_Grand_Am' },
    { grandprix: 'https://en.wikipedia.org/wiki/Pontiac_Grand_Prix' },
    { gto: 'https://en.wikipedia.org/wiki/Pontiac_GTO' },
    { lemans: 'https://en.wikipedia.org/wiki/Pontiac_LeMans' },
    { montana: 'https://en.wikipedia.org/wiki/Pontiac_Montana' },
    { pursuit: 'https://en.wikipedia.org/wiki/Pontiac_Pursuit' },
    { solstice: 'https://en.wikipedia.org/wiki/Pontiac_Solstice' },
    { sunbird: 'https://en.wikipedia.org/wiki/Pontiac_Sunbird' },
    { sunfire: 'https://en.wikipedia.org/wiki/Pontiac_Sunfire' },
    { sunrunner: 'https://en.wikipedia.org/wiki/Pontiac_Sunrunner' },
    { tempest: 'https://en.wikipedia.org/wiki/Pontiac_Tempest' },
    { torrent: 'https://en.wikipedia.org/wiki/Pontiac_Torrent' },
    { trans_sport: 'https://en.wikipedia.org/wiki/Pontiac_Trans_Sport' },
    { transam: 'https://en.wikipedia.org/wiki/Pontiac_Trans_Am' },
    { vibe: 'https://en.wikipedia.org/wiki/Pontiac_Vibe' },
    { wave: 'https://en.wikipedia.org/wiki/Pontiac_Wave' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Pontiac' },
  ],
  porsche: [
    { '356': 'https://en.wikipedia.org/wiki/Porsche_356' },
    { '911': 'https://en.wikipedia.org/wiki/Porsche_911' },
    { '912': 'https://en.wikipedia.org/wiki/Porsche_912' },
    { '914': 'https://en.wikipedia.org/wiki/Porsche_914' },
    { '924': 'https://en.wikipedia.org/wiki/Porsche_924' },
    { '928': 'https://en.wikipedia.org/wiki/Porsche_928' },
    { '930': 'https://en.wikipedia.org/wiki/Porsche_930' },
    { '944': 'https://en.wikipedia.org/wiki/Porsche_944' },
    { '968': 'https://en.wikipedia.org/wiki/Porsche_968' },
    { boxster: 'https://en.wikipedia.org/wiki/Porsche_Boxster' },
    { carrera: 'https://en.wikipedia.org/wiki/Porsche_Carrera' },
    { cayenne: 'https://en.wikipedia.org/wiki/Porsche_Cayenne' },
    { cayman: 'https://en.wikipedia.org/wiki/Porsche_Cayman' },
    { panamera: 'https://en.wikipedia.org/wiki/Porsche_Panamera' },
    { macan: 'https://en.wikipedia.org/wiki/Porsche_Macan' },
    { '718': 'https://en.wikipedia.org/wiki/Porsche_718' },
    { taycan: 'https://en.wikipedia.org/wiki/Porsche_Taycan' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Porsche' },
  ],
  ram: [
    { '1500': 'https://en.wikipedia.org/wiki/Ram_1500' },
    { '2500': 'https://en.wikipedia.org/wiki/Ram_2500' },
    { '3500': 'https://en.wikipedia.org/wiki/Ram_3500' },
    { cargo: 'https://en.wikipedia.org/wiki/Ram_Cargo_Van' },
    { promaster_city: 'https://en.wikipedia.org/wiki/Ram_ProMaster_City' },
    { promaster: 'https://en.wikipedia.org/wiki/Ram_ProMaster' },
    { dakota: 'https://en.wikipedia.org/wiki/Dodge_Dakota' },
  ],
  renault: [
    { alliance: 'https://en.wikipedia.org/wiki/Renault_Alliance' },
    { dauphine: 'https://en.wikipedia.org/wiki/Renault_Dauphine' },
    { encore: 'https://en.wikipedia.org/wiki/Renault_Encore' },
    { fuego: 'https://en.wikipedia.org/wiki/Renault_Fuego' },
    { medallion: 'https://en.wikipedia.org/wiki/Renault_Medallion' },
    { rambler: 'https://en.wikipedia.org/wiki/Renault_Rambler' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Renault' },
  ],
  rollsroyce: [
    { corniche: 'https://en.wikipedia.org/wiki/Rolls-Royce_Corniche' },
    { phantom: 'https://en.wikipedia.org/wiki/Rolls-Royce_Phantom' },
    { slvrshdw: 'https://en.wikipedia.org/wiki/Rolls-Royce_Silver_Shadow' },
    { slvrsprtsprdwn: 'https://en.wikipedia.org/wiki/Rolls-Royce_Silver_Spirit' },
    { slvrsrph: 'https://en.wikipedia.org/wiki/Rolls-Royce_Silver_Seraph' },
    { cullinan: 'https://en.wikipedia.org/wiki/Rolls-Royce_Cullinan' },
    { dawn: 'https://en.wikipedia.org/wiki/Rolls-Royce_Dawn' },
    { ghost: 'https://en.wikipedia.org/wiki/Rolls-Royce_Ghost' },
    { wraith: 'https://en.wikipedia.org/wiki/Rolls-Royce_Wraith' },
    { silver_dawn: 'https://en.wikipedia.org/wiki/Rolls-Royce_Silver_Dawn' },
    { silver_spur: 'https://en.wikipedia.org/wiki/Rolls-Royce_Silver_Spur' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Rolls-Royce' },
  ],
  saab: [
    { '9-3x': 'https://en.wikipedia.org/wiki/Saab_9-3' },
    { '900': 'https://en.wikipedia.org/wiki/Saab_900' },
    { '9000': 'https://en.wikipedia.org/wiki/Saab_9000' },
    { '92x': 'https://en.wikipedia.org/wiki/Saab_92X' },
    { '93': 'https://en.wikipedia.org/wiki/Saab_93' },
    { '95': 'https://en.wikipedia.org/wiki/Saab_95' },
    { '97x': 'https://en.wikipedia.org/wiki/Saab_97X' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Saab' },
  ],
  saturn: [
    { astra: 'https://en.wikipedia.org/wiki/Saturn_Astra' },
    { aura: 'https://en.wikipedia.org/wiki/Saturn_Aura' },
    { ion: 'https://en.wikipedia.org/wiki/Saturn_Ion' },
    { outlook: 'https://en.wikipedia.org/wiki/Saturn_Outlook' },
    { relay: 'https://en.wikipedia.org/wiki/Saturn_Relay' },
    { 's-series': 'https://en.wikipedia.org/wiki/Saturn_S-Series' },
    { sky: 'https://en.wikipedia.org/wiki/Saturn_Sky' },
    { vue: 'https://en.wikipedia.org/wiki/Saturn_Vue' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Saturn' },
    { lseries: 'https://en.wikipedia.org/wiki/Saturn_L-Series' },
  ],
  scion: [
    { tc: 'https://en.wikipedia.org/wiki/Scion_tC' },
    { xa: 'https://en.wikipedia.org/wiki/Scion_xA' },
    { xb: 'https://en.wikipedia.org/wiki/Scion_xB' },
    { xd: 'https://en.wikipedia.org/wiki/Scion_xD' },
    { iq: 'https://en.wikipedia.org/wiki/Scion_iQ' },
    { frs: 'https://en.wikipedia.org/wiki/Subaru_BRZ' },
    { im: 'https://en.wikipedia.org/wiki/Scion_iM' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Scion' },
  ],
  shelby: [
    { chrgr: 'https://en.wikipedia.org/wiki/Shelby_Charger' },
    { cobra: 'https://en.wikipedia.org/wiki/Shelby_Cobra' },
    { csx: 'https://en.wikipedia.org/wiki/Shelby_CSX' },
    { dakota: 'https://en.wikipedia.org/wiki/Dodge_Dakota' },
    { daytona: 'https://en.wikipedia.org/wiki/Shelby_Daytona' },
    { glhs: 'https://en.wikipedia.org/wiki/Shelby_GLHS' },
    { lancer: 'https://en.wikipedia.org/wiki/Shelby_Lancer' },
    { mstnggt: 'https://en.wikipedia.org/wiki/Ford_Mustang' },
    { series1: 'https://en.wikipedia.org/wiki/Shelby_Series_1' },
    { viper: 'https://en.wikipedia.org/wiki/Dodge_Viper' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Shelby' },
  ],
  smart: [
    { fortwo: 'https://en.wikipedia.org/wiki/Smart_Fortwo' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Smart' },
  ],
  subaru: [
    { b9_tribeca: 'https://en.wikipedia.org/wiki/Subaru_B9_Tribeca' },
    { baja: 'https://en.wikipedia.org/wiki/Subaru_Baja' },
    { forester: 'https://en.wikipedia.org/wiki/Subaru_Forester' },
    { impreza: 'https://en.wikipedia.org/wiki/Subaru_Impreza' },
    { impreza_wrx_sti: 'https://en.wikipedia.org/wiki/Subaru_WRX_STI' },
    { legacy: 'https://en.wikipedia.org/wiki/Subaru_Legacy' },
    { outback: 'https://en.wikipedia.org/wiki/Subaru_Outback' },
    { svx: 'https://en.wikipedia.org/wiki/Subaru_SVX' },
    { tribeca: 'https://en.wikipedia.org/wiki/Subaru_Tribeca' },
    { wrx: 'https://en.wikipedia.org/wiki/Subaru_WRX' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Subaru' },
    { brz: 'https://en.wikipedia.org/wiki/Subaru_BRZ' },
    { ascent: 'https://en.wikipedia.org/wiki/Subaru_Ascent' },
    { '86': 'https://en.wikipedia.org/wiki/Subaru_86' },
    { prius_c: 'https://en.wikipedia.org/wiki/Toyota_Prius_c' },
    { xv_crosstrek: 'https://en.wikipedia.org/wiki/Subaru_Crosstrek' },
  ],
  suzuki: [
    { aerio: 'https://en.wikipedia.org/wiki/Suzuki_Aerio' },
    { equator: 'https://en.wikipedia.org/wiki/Suzuki_Equator' },
    { esteem: 'https://en.wikipedia.org/wiki/Suzuki_Esteem' },
    { grndvitara: 'https://en.wikipedia.org/wiki/Suzuki_Grand_Vitara' },
    { kizashi: 'https://en.wikipedia.org/wiki/Suzuki_Kizashi' },
    { sidekick: 'https://en.wikipedia.org/wiki/Suzuki_Sidekick' },
    { swift: 'https://en.wikipedia.org/wiki/Suzuki_Swift' },
    { sx4: 'https://en.wikipedia.org/wiki/Suzuki_SX4' },
    { verona: 'https://en.wikipedia.org/wiki/Suzuki_Verona' },
    { vitara: 'https://en.wikipedia.org/wiki/Suzuki_Vitara' },
    { 'x-90': 'https://en.wikipedia.org/wiki/Suzuki_X-90' },
    { xl7: 'https://en.wikipedia.org/wiki/Suzuki_XL7' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Suzuki' },
  ],
  tesla: [
    { model: 'https://en.wikipedia.org/wiki/Tesla_Model_S' },
    { other: 'https://en.wikipedia.org/wiki/Tesla' },
    { model_x: 'https://en.wikipedia.org/wiki/Tesla_Model_X' },
    { model_3: 'https://en.wikipedia.org/wiki/Tesla_Model_3' },
    { model_y: 'https://en.wikipedia.org/wiki/Tesla_Model_Y' },
  ],
  toyota: [
    { '4runner': 'https://en.wikipedia.org/wiki/Toyota_4Runner' },
    { avalon: 'https://en.wikipedia.org/wiki/Toyota_Avalon' },
    { camry: 'https://en.wikipedia.org/wiki/Toyota_Camry' },
    { celica: 'https://en.wikipedia.org/wiki/Toyota_Celica' },
    { corolla: 'https://en.wikipedia.org/wiki/Toyota_Corolla' },
    { echo: 'https://en.wikipedia.org/wiki/Toyota_Echo' },
    { fjcruiser: 'https://en.wikipedia.org/wiki/Toyota_FJ_Cruiser' },
    { highlander: 'https://en.wikipedia.org/wiki/Toyota_Highlander' },
    { lndcrsr: 'https://en.wikipedia.org/wiki/Toyota_Land_Cruiser' },
    { matrix: 'https://en.wikipedia.org/wiki/Toyota_Matrix' },
    { mr2: 'https://en.wikipedia.org/wiki/Toyota_MR2' },
    { paseo: 'https://en.wikipedia.org/wiki/Toyota_Paseo' },
    { previa: 'https://en.wikipedia.org/wiki/Toyota_Previa' },
    { prius: 'https://en.wikipedia.org/wiki/Toyota_Prius' },
    { prius_v: 'https://en.wikipedia.org/wiki/Toyota_Prius_v' },
    { rav4: 'https://en.wikipedia.org/wiki/Toyota_RAV4' },
    { sequoia: 'https://en.wikipedia.org/wiki/Toyota_Sequoia' },
    { sienna: 'https://en.wikipedia.org/wiki/Toyota_Sienna' },
    { solara: 'https://en.wikipedia.org/wiki/Toyota_Solara' },
    { supra: 'https://en.wikipedia.org/wiki/Toyota_Supra' },
    { t100: 'https://en.wikipedia.org/wiki/Toyota_T100' },
    { tacoma: 'https://en.wikipedia.org/wiki/Toyota_Tacoma' },
    { tercel: 'https://en.wikipedia.org/wiki/Toyota_Tercel' },
    { tundra: 'https://en.wikipedia.org/wiki/Toyota_Tundra' },
    { venza: 'https://en.wikipedia.org/wiki/Toyota_Venza' },
    { yaris: 'https://en.wikipedia.org/wiki/Toyota_Yaris' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Toyota' },
    { othrpkups: 'https://en.wikipedia.org/wiki/Toyota' },
    { 'c-hr': 'https://en.wikipedia.org/wiki/Toyota_C-HR' },
    { prius_prime: 'https://en.wikipedia.org/wiki/Toyota_Prius_Plug-in' },
    { mirai: 'https://en.wikipedia.org/wiki/Toyota_Mirai' },
  ],
  triumph: [
    { spitfire: 'https://en.wikipedia.org/wiki/Triumph_Spitfire' },
    { tr6: 'https://en.wikipedia.org/wiki/Triumph_TR6' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Triumph' },
  ],
  volkswagen: [
    { beetle: 'https://en.wikipedia.org/wiki/Volkswagen_Beetle' },
    { btlnew: 'https://en.wikipedia.org/wiki/Volkswagen_New_Beetle' },
    { busvngn: 'https://en.wikipedia.org/wiki/Volkswagen_Type_2' },
    { cabrio: 'https://en.wikipedia.org/wiki/Volkswagen_Cabrio' },
    { cc: 'https://en.wikipedia.org/wiki/Volkswagen_CC' },
    { eos: 'https://en.wikipedia.org/wiki/Volkswagen_Eos' },
    { eurovan: 'https://en.wikipedia.org/wiki/Volkswagen_Eurovan' },
    { gli: 'https://en.wikipedia.org/wiki/Volkswagen_GLI' },
    { golf: 'https://en.wikipedia.org/wiki/Volkswagen_Golf' },
    { gti: 'https://en.wikipedia.org/wiki/Volkswagen_GTI' },
    { jetta: 'https://en.wikipedia.org/wiki/Volkswagen_Jetta' },
    { krmnngh: 'https://en.wikipedia.org/wiki/Volkswagen_Karmann_Ghia' },
    { passat: 'https://en.wikipedia.org/wiki/Volkswagen_Passat' },
    { phaeton: 'https://en.wikipedia.org/wiki/Volkswagen_Phaeton' },
    { rabbit: 'https://en.wikipedia.org/wiki/Volkswagen_Rabbit' },
    { routan: 'https://en.wikipedia.org/wiki/Volkswagen_Routan' },
    { thing: 'https://en.wikipedia.org/wiki/Volkswagen_Thing' },
    { tiguan: 'https://en.wikipedia.org/wiki/Volkswagen_Tiguan' },
    { touareg: 'https://en.wikipedia.org/wiki/Volkswagen_Touareg' },
    { transporter: 'https://en.wikipedia.org/wiki/Volkswagen_Transporter' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Volkswagen' },
    { atlas: 'https://en.wikipedia.org/wiki/Volkswagen_Atlas' },
    { arteon: 'https://en.wikipedia.org/wiki/Volkswagen_Arteon' },
    { golf_r: 'https://en.wikipedia.org/wiki/Volkswagen_Golf_R' },
  ],
  volvo: [
    { '240': 'https://en.wikipedia.org/wiki/Volvo_240' },
    { '740': 'https://en.wikipedia.org/wiki/Volvo_740' },
    { '850': 'https://en.wikipedia.org/wiki/Volvo_850' },
    { '940': 'https://en.wikipedia.org/wiki/Volvo_940' },
    { '960': 'https://en.wikipedia.org/wiki/Volvo_960' },
    { c30: 'https://en.wikipedia.org/wiki/Volvo_C30' },
    { c70: 'https://en.wikipedia.org/wiki/Volvo_C70' },
    { s40: 'https://en.wikipedia.org/wiki/Volvo_S40' },
    { s60: 'https://en.wikipedia.org/wiki/Volvo_S60' },
    { s70: 'https://en.wikipedia.org/wiki/Volvo_S70' },
    { s80: 'https://en.wikipedia.org/wiki/Volvo_S80' },
    { s90: 'https://en.wikipedia.org/wiki/Volvo_S90' },
    { v40: 'https://en.wikipedia.org/wiki/Volvo_V40' },
    { v50: 'https://en.wikipedia.org/wiki/Volvo_V50' },
    { v70: 'https://en.wikipedia.org/wiki/Volvo_V70' },
    { v90: 'https://en.wikipedia.org/wiki/Volvo_V90' },
    { xc60: 'https://en.wikipedia.org/wiki/Volvo_XC60' },
    { xc70: 'https://en.wikipedia.org/wiki/Volvo_XC70' },
    { xc90: 'https://en.wikipedia.org/wiki/Volvo_XC90' },
    { xccc: 'https://en.wikipedia.org/wiki/Volvo_XC' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Volvo' },
    { v60: 'https://en.wikipedia.org/wiki/Volvo_V60' },
    { xc40: 'https://en.wikipedia.org/wiki/Volvo_XC40' },
    { s60_cross_country: 'https://en.wikipedia.org/wiki/Volvo_S60_Cross_Country' },
    { v40_cross_country: 'https://en.wikipedia.org/wiki/Volvo_V40_Cross_Country' },
    { v60_cross_country: 'https://en.wikipedia.org/wiki/Volvo_V60_Cross_Country' },
    { v90_cross_country: 'https://en.wikipedia.org/wiki/Volvo_V90_Cross_Country' },
  ],
  mercedes: [
    { '190series': 'https://en.wikipedia.org/wiki/Mercedes-Benz_190' },
    { '200series': 'https://en.wikipedia.org/wiki/Mercedes-Benz_200' },
    { '300series': 'https://en.wikipedia.org/wiki/Mercedes-Benz_300' },
    { '400series': 'https://en.wikipedia.org/wiki/Mercedes-Benz_400' },
    { '500series': 'https://en.wikipedia.org/wiki/Mercedes-Benz_500' },
    { '600series': 'https://en.wikipedia.org/wiki/Mercedes-Benz_600' },
    { bclass: 'https://en.wikipedia.org/wiki/Mercedes-Benz_B-Class' },
    { cclass: 'https://en.wikipedia.org/wiki/Mercedes-Benz_C-Class' },
    { clclass: 'https://en.wikipedia.org/wiki/Mercedes-Benz_CL-Class' },
    { clkclass: 'https://en.wikipedia.org/wiki/Mercedes-Benz_CLK-Class' },
    { clsclass: 'https://en.wikipedia.org/wiki/Mercedes-Benz_CLS-Class' },
    { eclass: 'https://en.wikipedia.org/wiki/Mercedes-Benz_E-Class' },
    { gclass: 'https://en.wikipedia.org/wiki/Mercedes-Benz_G-Class' },
    { glclass: 'https://en.wikipedia.org/wiki/Mercedes-Benz_GL-Class' },
    { 'glk-class': 'https://en.wikipedia.org/wiki/Mercedes-Benz_GLK-Class' },
    { mclass: 'https://en.wikipedia.org/wiki/Mercedes-Benz_M-Class' },
    { rclass: 'https://en.wikipedia.org/wiki/Mercedes-Benz_R-Class' },
    { sclass: 'https://en.wikipedia.org/wiki/Mercedes-Benz_S-Class' },
    { slclass: 'https://en.wikipedia.org/wiki/Mercedes-Benz_SL-Class' },
    { slkclass: 'https://en.wikipedia.org/wiki/Mercedes-Benz_SLK-Class' },
    { slrmclaren: 'https://en.wikipedia.org/wiki/Mercedes-Benz_SLR_McLaren' },
    { sls_amg: 'https://en.wikipedia.org/wiki/Mercedes-Benz_SLS_AMG' },
    { 'slsamg-class': 'https://en.wikipedia.org/wiki/Mercedes-Benz_SLS_AMG' },
    { sprinter_van: 'https://en.wikipedia.org/wiki/Mercedes-Benz_Sprinter' },
    { sprinter_wagon: 'https://en.wikipedia.org/wiki/Mercedes-Benz_Sprinter' },
    { 'sprintervan-class': 'https://en.wikipedia.org/wiki/Mercedes-Benz_Sprinter' },
    { 'sprinterwagon-class': 'https://en.wikipedia.org/wiki/Mercedes-Benz_Sprinter' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Mercedes-Benz' },
    { a_class: 'https://en.wikipedia.org/wiki/Mercedes-Benz_A-Class' },
    { amg_gt: 'https://en.wikipedia.org/wiki/Mercedes-AMG_GT' },
    { cla: 'https://en.wikipedia.org/wiki/Mercedes-Benz_CLA-Class' },
    { gla: 'https://en.wikipedia.org/wiki/Mercedes-Benz_GLA-Class' },
    { glc: 'https://en.wikipedia.org/wiki/Mercedes-Benz_GLC-Class' },
    { gle: 'https://en.wikipedia.org/wiki/Mercedes-Benz_GLE-Class' },
    { gls: 'https://en.wikipedia.org/wiki/Mercedes-Benz_GLS-Class' },
    { slc: 'https://en.wikipedia.org/wiki/Mercedes-Benz_SLC-Class' },
    { metris_van: 'https://en.wikipedia.org/wiki/Mercedes-Benz_Metris' },
    { eqc: 'https://en.wikipedia.org/wiki/Mercedes-Benz_EQC' },
    { glb: 'https://en.wikipedia.org/wiki/Mercedes-Benz_GLB-Class' },
  ],
  mercury: [
    { capri: 'https://en.wikipedia.org/wiki/Mercury_Capri' },
    { comet: 'https://en.wikipedia.org/wiki/Mercury_Comet' },
    { cougar: 'https://en.wikipedia.org/wiki/Mercury_Cougar' },
    { grndmrqus: 'https://en.wikipedia.org/wiki/Mercury_Grand_Marquis' },
    { mariner: 'https://en.wikipedia.org/wiki/Mercury_Mariner' },
    { milan: 'https://en.wikipedia.org/wiki/Mercury_Milan' },
    { mntnr: 'https://en.wikipedia.org/wiki/Mercury_Mountaineer' },
    { montego: 'https://en.wikipedia.org/wiki/Mercury_Montego' },
    { monterey: 'https://en.wikipedia.org/wiki/Mercury_Monterey' },
    { mystique: 'https://en.wikipedia.org/wiki/Mercury_Mystique' },
    { sable: 'https://en.wikipedia.org/wiki/Mercury_Sable' },
    { tracer: 'https://en.wikipedia.org/wiki/Mercury_Tracer' },
    { villager: 'https://en.wikipedia.org/wiki/Mercury_Villager' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Mercury_(automobile)' },
  ],
  mg: [
    { mga: 'https://en.wikipedia.org/wiki/MG_A' },
    { mgb: 'https://en.wikipedia.org/wiki/MG_B' },
    { midget: 'https://en.wikipedia.org/wiki/MG_Midget' },
    { tseries: 'https://en.wikipedia.org/wiki/MG_T_series' },
    { othrmdl: 'https://en.wikipedia.org/wiki/MG_(automobile)' },
  ],
  mini: [
    { clsscmini: 'https://en.wikipedia.org/wiki/Mini_Classic' },
    { clubman: 'https://en.wikipedia.org/wiki/Mini_Clubman' },
    { cooper_classic_clubman: 'https://en.wikipedia.org/wiki/Mini_Clubman' },
    { cooper_countryman: 'https://en.wikipedia.org/wiki/Mini_Countryman' },
    { cooper_s_countryman: 'https://en.wikipedia.org/wiki/Mini_Countryman' },
    { john_cooper_works: 'https://en.wikipedia.org/wiki/John_Cooper_Works' },
    { john_cooper_works_clubman: 'https://en.wikipedia.org/wiki/Mini_Clubman' },
    { minicp: 'https://en.wikipedia.org/wiki/Mini' },
    { minicps: 'https://en.wikipedia.org/wiki/Mini' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Mini' },
    { paceman: 'https://en.wikipedia.org/wiki/Mini_Paceman' },
    { john_cooper_works_countryman: 'https://en.wikipedia.org/wiki/Mini_Countryman' },
  ],
  lmbrghn: [
    { gallardo: 'https://en.wikipedia.org/wiki/Lamborghini_Gallardo' },
    { murcielago: 'https://en.wikipedia.org/wiki/Lamborghini_Murciélago' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Lamborghini' },
    { aventador: 'https://en.wikipedia.org/wiki/Lamborghini_Aventador' },
    { huracán: 'https://en.wikipedia.org/wiki/Lamborghini_Huracán' },
    { urus: 'https://en.wikipedia.org/wiki/Lamborghini_Urus' },
  ],
  lotus: [
    { elise: 'https://en.wikipedia.org/wiki/Lotus_Elise' },
    { esprit: 'https://en.wikipedia.org/wiki/Lotus_Esprit' },
    { exige: 'https://en.wikipedia.org/wiki/Lotus_Exige' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Lotus_Cars' },
    { evora: 'https://en.wikipedia.org/wiki/Lotus_Evora' },
  ],
  maserati: [
    { coupe: 'https://en.wikipedia.org/wiki/Maserati_Coupe' },
    { grntrsm: 'https://en.wikipedia.org/wiki/Maserati_GranTurismo' },
    { quttrprt: 'https://en.wikipedia.org/wiki/Maserati_Quattroporte' },
    { spyder: 'https://en.wikipedia.org/wiki/Maserati_Spyder' },
    { othrmdl: 'https://en.wikipedia.org/wiki/Maserati' },
    { ghibli: 'https://en.wikipedia.org/wiki/Maserati_Ghibli' },
    { levante: 'https://en.wikipedia.org/wiki/Maserati_Levante' },
  ],
  maybach: [{ othrmdl: 'https://en.wikipedia.org/wiki/Maybach' }],
}

/**
 * Retrieves the Wikipedia link for a specific car make and model.
 *
 * @param carMake - The make of the car.
 * @param carModel - The model of the car.
 * @returns The Wikipedia link for the specified car make and model, or undefined if not found.
 */
export const getAutosWikipediaLink = (carMake: string, carModel: string) => {
  const makeData = AUTOS_WIKIPEDIA_LINKS[carMake] ?? []
  const modelData = makeData.find((m) => m[carModel])
  return modelData?.[carModel]
}
