import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgRehome: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="rehome_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={32}
      >
        <path fill="currentColor" d="M0 0h31.997v31.997H0z" />
      </mask>
      <g mask="url(#rehome_svg__a)">
        <path
          d="M15.999 5.692l12.768 9.563-.83 1.056-2.606-1.953v12.307H6.666V14.358L4.061 16.31l-.83-1.056 12.768-9.563zm-4.662 10.931c0 .892.48 1.841 1.44 2.85a63.48 63.48 0 003.222 3.14 53.864 53.864 0 003.274-3.138c.959-1.007 1.438-1.956 1.438-2.845 0-.739-.253-1.353-.76-1.844a2.527 2.527 0 00-1.822-.736 2.6 2.6 0 00-1.196.273 3.46 3.46 0 00-.934.686 2.712 2.712 0 00-.904-.686 2.622 2.622 0 00-1.167-.273c-.711 0-1.32.244-1.829.732-.508.487-.761 1.101-.761 1.841zm12.661 8.708V13.332l-8-6-7.999 6v12h16z"
          fill="currentColor"
        />
      </g>
    </Icon>
  )
}
export default SvgRehome
