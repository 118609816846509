import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { ImageYAMS } from '@/components/shared/image/ImageYAMS'
import { type ShowcaseThumbnailSizes } from '@/components/srp/showcase/Showcase'
import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

// TODO: Remove this once we have a proper image component
export const DEFAULT_THUMBNAIL_SIZE = 80

export const ShowcaseContainer = styled.div`
  position: relative;
  z-index: ${zIndexRegistry.CARD_ELEMENTS};
`

export const ShowcaseViewport = styled.div`
  overflow: hidden;
`

type ShowcaseListProps = {
  gap: keyof ThemeProps['spacing']
}
export const ShowcaseList = styled.ul<ShowcaseListProps>(
  ({ theme, gap }) => `
  display: flex;
  flex-direction: row;
  height: auto;
  gap: ${theme.spacing[gap]};

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
)

type ShowcaseItemProps = {
  borderRadius?: keyof ThemeProps['borderRadius']
  thumbnailSizes: ShowcaseThumbnailSizes
}
export const ShowcaseItem = styled.li<ShowcaseItemProps>(
  ({ theme, borderRadius, thumbnailSizes }) => `
  flex: 0 0 ${thumbnailSizes.small.width}px;
  min-width: 0;
  position: relative;

  // Temporary media-query for the AutosListingCard experiment. We'll update the MEDIA_QUERIES once we validate the experiment.
  ${MEDIA_QUERIES(theme).mediumSmall}{
    flex: 0 0 ${thumbnailSizes.mediumSmall.width}px;
  }

  ${MEDIA_QUERIES(theme).medium}{
    flex: 0 0 ${thumbnailSizes.medium.width}px;
  }

  ${MEDIA_QUERIES(theme).xLarge}{
    flex: 0 0 ${thumbnailSizes.xLarge.width}px;
  }

  ${
    borderRadius
      ? `
    overflow: hidden;
    border-radius: ${theme.borderRadius[borderRadius]};
  `
      : ''
  }
 
`
)

export const ShowcasePicture = styled(ImageYAMS)(
  () => `
  object-fit: cover;
  transition: transform .3s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
`
)

type ShowcasePictureWrapperProps = {
  thumbnailSizes: ShowcaseThumbnailSizes
}
export const ShowcasePictureWrapper = styled.div<ShowcasePictureWrapperProps>(
  ({ theme, thumbnailSizes }) => `
  border-radius: ${theme.borderRadius.small};
  overflow: hidden;
  height: ${thumbnailSizes.small.height}px;
  position: relative;

  // Temporary media-query for the AutosListingCard experiment. We'll update the MEDIA_QUERIES once we validate the experiment.
  ${MEDIA_QUERIES(theme).mediumSmall}{
    height: ${thumbnailSizes.mediumSmall.height}px;
  }

  ${MEDIA_QUERIES(theme).medium}{
    height: ${thumbnailSizes.medium.height}px;
  }

  ${MEDIA_QUERIES(theme).xLarge}{
    height: ${thumbnailSizes.xLarge.height}px;
  }

  `
)
