import { ListingPriceRating } from '@kijiji/generated/graphql-types'
import { type ThemeProps } from '@kijiji/theme'

type PriceInsightColorMap = {
  background: string
  border: string
  progressBar: string
}

export const PRICE_INSIGHT_COLOUR_MAP = (
  colors: ThemeProps['colors']
): Record<string, PriceInsightColorMap> =>
  ({
    [ListingPriceRating.Great]: {
      background: colors.green.light4,
      border: colors.green.light4,
      progressBar: colors.green.primary,
    },
    [ListingPriceRating.Good]: {
      background: colors.green.light5,
      border: colors.green.light3,
      progressBar: colors.green.primary,
    },
    [ListingPriceRating.Fair]: {
      background: colors.yellow.light5,
      border: colors.yellow.light3,
      progressBar: colors.yellow.primary,
    },
    [ListingPriceRating.Poor]: {
      background: colors.red.light5,
      border: colors.red.light3,
      progressBar: colors.red.primary,
    },
  }) satisfies Record<ListingPriceRating, PriceInsightColorMap>
