import styled from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

export const ListingDetailsContainer = styled.div(
  ({ theme }) => `
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    column-gap: ${theme.spacing.defaultSmall};

    & > p {
      text-wrap: nowrap;
    }

    ${MEDIA_QUERIES(theme).medium}{
      & > p {
        ${styleFromTypography(theme.typography.body['small'])}
      }
    }
`
)

export const VerticalDivider = styled.div(
  ({ theme }) => `
    width: 0.1rem;
    height: ${theme.spacing.default};
    background: ${theme.colors.grey.light2};
`
)

export const SortingDateLabel = styled.div<{ hasTopProvAdEnabled: boolean }>(
  ({ theme, hasTopProvAdEnabled }) => `
  display: ${hasTopProvAdEnabled ? 'none' : 'flex'};
  gap: ${hasTopProvAdEnabled ? '0' : theme.spacing.defaultSmall};

  ${MEDIA_QUERIES(theme).medium} {
    display: flex;
    gap: ${theme.spacing.defaultSmall};

    & > p {
      ${styleFromTypography(theme.typography.body['small'])}
    }
  }
  `
)

export const ListingLocationLabel = styled(BodyText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ListingProximityLabel = styled(BodyText)`
  white-space: nowrap;
`
