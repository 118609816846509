import { isRealEstateSellOrRentCategory } from '@kijiji/category'
import {
  type ListingLocation,
  type RealEstateListingLocation,
} from '@kijiji/generated/graphql-types'

import { isRealEstateListingLocation } from '@/types/listings'

/**
 * It should return true if the listing is a real estate listing (within the for sale or for rent categories)
 * and has neighbourhood info available.
 * @param listing - The listing to check
 * @returns True if the listing is a real estate listing and has neighbourhood info available, false otherwise
 */
export const shouldRenderNeighbourhoodLocationInfo = (
  categoryId: number,
  location: ListingLocation | RealEstateListingLocation
): location is RealEstateListingLocation => {
  return !!(
    isRealEstateSellOrRentCategory(categoryId) &&
    isRealEstateListingLocation(location) && // Type guard
    location.neighbourhoodInfo?.name
  )
}
