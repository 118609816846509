import { type ShowcaseThumbnailSizes } from '@/components/srp/showcase/Showcase'

export const autosListingMainImageUrlRuleSize = 400

export const autosShowcaseThumbnailSizes: ShowcaseThumbnailSizes = {
  xLarge: {
    width: 74.67,
    height: 56,
  },
  medium: {
    width: 64,
    height: 48,
  },
  mediumSmall: {
    width: 53.33,
    height: 40,
  },
  small: {
    width: 36,
    height: 28,
  },
}

/**
 * Those are the valid API returned values for Badges for listings that have acquired a CARFAX report
 */
export const CAR_HISTORY_BADGES = {
  ACCIDENT_FREE: 'accidentFree',
  LOW_KILOMETERS: 'lowKilometers',
  ONE_OWNER: 'oneOwner',
  TOP_CONDITION: 'topCondition',
} as const
