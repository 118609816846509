import { differenceInDays, differenceInMilliseconds } from 'date-fns'

import {
  DAY_IN_MS,
  HOUR_IN_MS,
  hoursFromMilliseconds,
  MINUTE_IN_MS,
  minutesFromMilliseconds,
  WEEK_IN_MS,
} from '@/utils/time'

type ListingSortingDateLabel = {
  /** Listing translation key */
  key: string

  /**
   * Translation parameters necessary when parsing the "key" field
   * @example
   * params: { minutes: '1', count: 1 }
   */
  params?: { [x: string]: string | number }
}

/**
 * It returns key and parameters to each type of time of activation label should be shown for each listing.
 * It will show a formatted date if the listing was posted more than a day ago.
 * The response of this function should be used with the "srp" translation function (i.e., t(key, {...params}))
 *
 * @param sortingDateTime
 * @param isMobileFormat
 *
 * @returns This function returns the listing translation key for activation date, and the parameters to be passed with that translation
 **/
export const getListingSortingDateLabel = (
  sortingDateTime: Date,
  isMobileFormat?: boolean
): ListingSortingDateLabel | null => {
  const currentTime = new Date()

  const timeDifferenceMS = differenceInMilliseconds(currentTime, sortingDateTime)
  /** Key appended to translation for shorter versions */
  const suffix = isMobileFormat ? '_short' : ''

  // Listing was posted Less than a minute ago
  if (timeDifferenceMS <= MINUTE_IN_MS) {
    return {
      key: `listing:activation_time.minutes${suffix}`,
      params: { minutes: '1', count: 1 },
    }
  }

  // Listing was posted less than an hour ago
  if (timeDifferenceMS < HOUR_IN_MS) {
    const minutes = minutesFromMilliseconds(timeDifferenceMS)
    return {
      key: `listing:activation_time.minutes${suffix}`,
      params: { minutes, count: minutes },
    }
  }

  // Listing was posted less than a day ago
  if (timeDifferenceMS < DAY_IN_MS) {
    const hours = hoursFromMilliseconds(timeDifferenceMS)
    return {
      key: `listing:activation_time.hours_ago${suffix}`,
      params: { hours, count: hours },
    }
  }

  // Listing was posted less than a week ago
  if (timeDifferenceMS < WEEK_IN_MS) {
    const days = differenceInDays(currentTime, sortingDateTime)
    return {
      key: `listing:activation_time.days${suffix}`,
      params: { days, count: days },
    }
  }

  // Listing was posted less than a month ago
  // Case 1 - where 4 weeks are considered as 1 month
  // Case 2 - for everything else we consider it as over a month ago
  const weeks = Math.floor(timeDifferenceMS / WEEK_IN_MS)
  if (weeks < 4) {
    return {
      key: `listing:activation_time.weeks${suffix}`,
      params: { weeks, count: weeks },
    }
  } else if (weeks >= 4) {
    return {
      key: `listing:activation_time.months`,
    }
  }

  // case default
  return null
}
