import { type GetListingQuery } from '@kijiji/generated/graphql-types'

import { ATTRIBUTES } from '@/features/attributes/constants/attributes'
import { getSeoAttributesDictionary } from '@/features/seo/utils/getSeoAttributesDictionary'
import { type Listing } from '@/types/search'
import { removeUndefinedFromObject } from '@/utils/removeUndefinedFromObj'

const { BEDROOMS, BATHROOMS, AREA_IN_FEET, PET_FRIENDLY, AGREEMENT_TYPE } = ATTRIBUTES

/**
 * A map of real estate attributes needed to populate the JSON-LD autos markup
 */
const RE_MARKUP_ATTRIBUTES: string[] = [
  BEDROOMS,
  BATHROOMS,
  AREA_IN_FEET,
  PET_FRIENDLY,
  AGREEMENT_TYPE,
]

/**
 * Returns a mounted markup for the real estate JSON-LD
 */
export const getRealEstateAttributesMarkup = (listing: Listing | GetListingQuery['listing']) => {
  const attributes = getSeoAttributesDictionary(listing, RE_MARKUP_ATTRIBUTES)

  const squareFootage = attributes[AREA_IN_FEET] ?? undefined

  const markup = {
    leaseLength: attributes[AGREEMENT_TYPE],
    numberOfBedrooms: attributes[BEDROOMS],
    numberOfBathroomsTotal: attributes[BATHROOMS],
    petsAllowed: attributes[PET_FRIENDLY],
    floorSize: squareFootage
      ? {
          '@type': 'QuantitativeValue',
          unitCode: 'FTK',
          value: squareFootage,
        }
      : undefined,
    numberOfRooms: attributes[BEDROOMS],
    address: listing?.location.address ?? undefined,
  }

  return removeUndefinedFromObject(markup)
}
