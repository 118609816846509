import {
  type GetListingQuery,
  type GetSearchResultsPageByUrlWithSeoQuery,
  type ListingLocation,
  type RealEstateListing,
  type RealEstateListingFlags,
  type RealEstateListingLocation,
} from '@kijiji/generated/graphql-types'

export type ListingBadgeLabel = 'featured' | 'urgent' | 'reduced'

export type VipQueryListing = NonNullable<GetListingQuery['listing']>
export type SrpResultListing = NonNullable<
  GetSearchResultsPageByUrlWithSeoQuery['searchResultsPageByUrl']['results']
>
export type VipQueryPrice = VipQueryListing['price']

type VipQueryAttributes = NonNullable<VipQueryListing['attributes']>
export type VipQueryAttributeAll = VipQueryAttributes['all']

export const isRealEstateListingType = (
  listing?: VipQueryListing
): listing is RealEstateListing => {
  return listing?.__typename === 'RealEstateListing'
}

export const isRealEstateListingFlags = (
  flags?: VipQueryListing['flags']
): flags is RealEstateListingFlags => {
  return flags?.__typename === 'RealEstateListingFlags'
}

export const isRealEstateListingLocation = (
  location?: ListingLocation | RealEstateListingLocation
): location is RealEstateListingLocation => {
  return location?.__typename === 'RealEstateListingLocation'
}
