import { useTheme } from 'styled-components'

import {
  AttributeListItemFlex,
  AttributeListItemText,
} from '@/components/srp/listing-card/autos-attribute-list/styled'

type AutosAttributeListItemProps = {
  text: string
}

/**
 * `AutosAttributeListItem` is a component that displays text as a list item.
 * It is intended to be used in the `AutosAttributeList` component.
 *
 * @component
 * @param {string} props.text - The text to be displayed in the list item.
 * @returns {ReactElement} Returns an `AutosAttributeListItem` React element.
 */
export const AutosAttributeListItem = ({ text, ...rest }: AutosAttributeListItemProps) => {
  const theme = useTheme()

  return (
    <AttributeListItemFlex {...rest}>
      <AttributeListItemText color={theme.colors.grey.primary}>{text}</AttributeListItemText>
    </AttributeListItemFlex>
  )
}
