import {
  type AdSource,
  type CoreListing,
  type GetPopularRelatedKeywordsQuery,
  type SearchResultsPage,
} from '@kijiji/generated/graphql-types'
import { RESPONSE_LIMIT_DEFAULT } from 'next/dist/server/api-utils'

import { getCurrentPageFromPagination } from '@/domain/srp/getCurrentPageFromPaginationData'
import { isDominantCategorySearch } from '@/domain/srp/isDominantCategorySearch'
import { DATALAYER_SRP_VIEW_TYPE } from '@/lib/ga/constants/datalayer'
import { GA_AD_TYPE_VALUE, isKeyOfGAAdType } from '@/lib/ga/constants/ga'
import { type DataLayerSearch } from '@/lib/ga/types/dataLayer'
import { getDatalayerAppliedFilters } from '@/lib/ga/utils/getDatalayerAppliedFilters'

type GetDatalayerSearch = {
  asPath: string
  /** When the DL is tracking impressions from listings that are not coming from the SRP */
  listingsData?: CoreListing[]
  /** When the search is from a SRP page */
  searchData?: SearchResultsPage
  searchPopularUrls?: GetPopularRelatedKeywordsQuery['searchUrlsPopular'] | null
  /** (SRP Only) View type of Search page */
  searchView?: DATALAYER_SRP_VIEW_TYPE
}

const getResultDataPerListing = ({
  adSource,
  index,
  listingId,
}: {
  adSource: AdSource
  index: number
  listingId: string
}) => {
  /** Results */
  const searchResult: NonNullable<DataLayerSearch['s']['sr']>[0] = {
    id: listingId,
    pos: index + 1,
    rt: isKeyOfGAAdType(adSource) ? GA_AD_TYPE_VALUE[adSource] : GA_AD_TYPE_VALUE.ORGANIC,
  }

  return searchResult
}

const getSearchDataFromCoreListingData = (coreListings: CoreListing[]): DataLayerSearch => {
  /** Results */
  const searchResults: DataLayerSearch['s']['sr'] = coreListings.map(({ adSource, id }, index) => {
    return getResultDataPerListing({ adSource, listingId: id, index })
  })

  return {
    s: {
      pn: 0,
      ps: coreListings.length,
      tp: 1,
      tr: coreListings.length,
      sr: searchResults,
      dcr: false,
      srt: { f: 'date', dr: 'DESC' },
    },
  }
}

export const getDatalayerSearch = ({
  asPath,
  listingsData,
  searchData,
  searchPopularUrls = [],
  searchView = DATALAYER_SRP_VIEW_TYPE.LIST,
}: GetDatalayerSearch): DataLayerSearch => {
  if (listingsData?.length) {
    return getSearchDataFromCoreListingData(listingsData)
  }

  const { controls, pagination, searchQuery, results } = searchData ?? {}
  const { mainListings = [], topListings = [] } = results ?? {}
  const { category, filters, keywords } = searchQuery ?? {}

  const limit = pagination?.limit ?? RESPONSE_LIMIT_DEFAULT
  const currentPage = getCurrentPageFromPagination({ offset: pagination?.offset, limit })

  const totalListings = pagination?.totalCount ?? 0

  const listings = [...topListings, ...mainListings]
  const totalPages = Math.ceil(totalListings / limit)

  /** Results */
  const searchResults: DataLayerSearch['s']['sr'] = listings.map((item, index) => {
    return getResultDataPerListing({ adSource: item.adSource, index, listingId: item.id })
  })

  /** Dominant Category */
  const isDomCat = isDominantCategorySearch(asPath)

  /** Sorting */
  const selectedSort = controls?.sorting.find((item) => item.isSelected)
  const sorting: DataLayerSearch['s']['srt'] = selectedSort
    ? { dr: selectedSort.direction, f: selectedSort.by }
    : undefined

  const datalayerSearch: DataLayerSearch = {
    s: {
      kw: keywords ?? undefined,
      pn: currentPage,
      ps: listings.length,
      tp: totalPages,
      tr: totalListings,
      rk: searchPopularUrls?.length,
      filters: getDatalayerAppliedFilters(filters),
      sr: searchResults,
      dcr: isDomCat,
      dc: category?.id ?? undefined,
      srt: sorting,
      v: searchView,
    },
  }

  return datalayerSearch
}
